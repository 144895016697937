import React from "react"
import Abstract from "../AbstractAuthComponent"
import { authDirectToken } from "../../actions/authActions"
import { connect } from "react-redux"
import { LoginComponent } from "../../../../layout/login/LoginComponent"
import "../login.scss"

import connector from "../../userAuthConnector"

class LdapLoginComponent extends LoginComponent {
  submit = () => {
    if (this.props.onChange) this.props.onChange(this.state.user.name, this.state.user.password)
  }
}

class LoginConnectComponent extends Abstract {
  login = (username, password) => {
    this.props.dispatch(authDirectToken({ username, password }))
  }

  render() {
    return (<div className="auth-wrapper" style={this.props.style}>
      {this.props.children}
      <LdapLoginComponent
        style={this.props.loginStyle}
        error={this.props.user.error} name={this.props.user.username}
                          password={this.props.user.password} pending={this.props.user.pending}
                          onChange={this.login}/>
    </div>)
  }

  componentDidUpdate() {
    if (this.props.onChange) this.props.onChange(this.props.user.credentialValid)
  }
}

export default connect(connector)(LoginConnectComponent)