import config from "../../../../config/config"
import { popSuccess } from "../../../team/actions/teamActions"

const log = () => {
}//console.log

const catchError = (lastError) => {
  if (lastError) {
    console.log(lastError.message);
    console.log("extension must be disabled or unavailable")
    return true
  }
  return false
}

const isChromeToolsAvailable = () => {
  return !!window.chrome &&
    !!window.chrome.runtime &&
    !!window.chrome.runtime.sendMessage
}

const runtime = () => {
  return window.chrome.runtime
}

const sendMessage = (a,b,fn) => {
  return runtime().sendMessage(a,b,fn)
}

const extensionActions = {
  install: ({ recipe }) => {
    if(!isChromeToolsAvailable()) return ;
    const payload = {
      trigger: "install-rule",
      data: recipe
    }
    sendMessage(config.extensionId, payload, (response) => {
      if(catchError(runtime().lastError))return;
      if (response) {
        popSuccess("Recipe installed successfully!")
        log("extension said", response)
      }
    })
  },
  enable: ({ recipe }) => {
    if(!isChromeToolsAvailable()) return ;
    const payload = {
      trigger: "enable-rule",
      data: recipe
    }
    sendMessage(config.extensionId, payload, (response) => {
      if(catchError(runtime().lastError))return;
      if (response) {
        popSuccess("Recipe enabled !")
        log("extension said", response)
      }
    })
  },
  disable: ({ recipe }) => {
    if(!isChromeToolsAvailable()) return ;
    const payload = {
      trigger: "disable-rule",
      data: recipe
    }
    sendMessage(config.extensionId, payload, (response) => {
      if(catchError(runtime().lastError))return;
      if (response) {
        popSuccess("Recipe disabled !")
        log("extension said", response)
      }
    })
  },
  remove: ({ recipe }) => {
    if(!isChromeToolsAvailable()) return ;
    const payload = {
      trigger: "remove-rule",
      data: recipe
    }
    sendMessage(config.extensionId, payload, (response) => {
      if(catchError(runtime().lastError))return;
      if (response) {
        popSuccess("Recipe removed !")
        log("extension said", response)
      }
    })
  },
  syncData: (callback) => {
    if(!isChromeToolsAvailable()) return ;
    const payload = {
      trigger: "full-sync"
    }
    sendMessage(config.extensionId, payload, (response) => {
      if(catchError(runtime().lastError))return;
      if (response) {
        log("extension said", response)
        if (callback) callback(response)
      }
    })
  }
}

export default extensionActions