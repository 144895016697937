//import { /*green900, green700, purple400, green700, blueGrey400,*/blueGrey900 } from "material-ui/styles/colors";
//import getMuiTheme from "material-ui/styles/getMuiTheme";


import { createMuiTheme } from "@material-ui/core/styles"
import blue from '@material-ui/core/colors/blue';
// import green from '@material-ui/core/colors/green';
// import purple from '@material-ui/core/colors/purple';
// import CssBaseline from '@material-ui/core/CssBaseline';

//
// const tableRowHeight = 36;
// const globalColor = blueGrey[900];
// const lightColor = "#FFF";

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    fontFamily: '\'Manrope\', \'Roboto\', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" '
  },
  //
  // appBar: {
  //   color: lightColor,
  //   textColor: globalColor
  // }
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700]
    },
    // secondary: {
    //   light: green[300],
    //   main: green[500],
    //   dark: green[700]
    // }
  }
})


//
//
// const selineTheme = getMuiTheme({
//   palette: {
//     primaryColor: globalColor
//   },
//   appBar: {
//     color: lightColor,
//     textColor: blueGrey900
//   },
//   drawer: {
//     color: globalColor
//   },
//   tableHeader: {
//     backgroundColor: "#FAFAFA"
//   },
//   tableRow: {
//     height: tableRowHeight
//   },
//   tableRowColumn: {
//     height: tableRowHeight
//   },
//   tableHeaderColumn: {
//     height: tableRowHeight
//   }
// });

export default theme
