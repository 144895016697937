import React  from "react"
import Page from "../layout/page/Page"
import Panel from "../layout/panel/Panel"
import { Jumbo } from "../components/home/Welcome"
import RegistrationConfirmation from "../components/auth/components/user/RegistrationConfirmation"


const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 200 }}/>
    </Jumbo>
  )
}



const SettingConfirmPage = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const email = params.get('email')
  return <Page header={<Heading/>} className="full-width">
    <div className="container">
      <Panel style={{ maxWidth: "600px", margin: "-200px auto 0 auto" }} bodyStyle={{ padding: 0 }}>
        <RegistrationConfirmation token={token} email={email} />
      </Panel>
    </div>
  </Page>
}

SettingConfirmPage.propTypes = {}
SettingConfirmPage.defaultProps = {}

export default SettingConfirmPage