import { Button } from "@material-ui/core"
import React from "react"
import sunny from '../../assets/images/logo-sunny-fr.png'
import './footer.scss'
import { Column, Row } from "../grid"
const styles = {
  column:{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 20px'
  },
  beta: {
    fontWeight: 800,
    margin: '0 10px',
    display: 'flex',
    backgroundColor: '#FC0',
    padding: '0 10px',
    borderRadius: 3,
    lineHeight: '24px',
    height: 24,
  },
  logo: {
    fontWeight: 800,
    lineHeight: '24px',
    height: 24,
    display: 'flex',
  }
}
function Footer() {
  return (<footer>
    <Row className="no-gutters" style={{height: '100%'}}>
      <Column forceClassName={'col'} style={styles.column}>

        <span style={styles.beta}>beta</span>  <span style={styles.logo}>Chimp</span>
      </Column>
      <Column forceClassName={'col'} style={{...styles.column, justifyContent:'flex-end', maxWidth: 300}}>
        <Button target="_blank" href="https://sunny.fr">
          <img alt="sunny.fr" style={{height: 16, filter: 'grayscale(90%)'}} src={sunny} />
        </Button>
      </Column>
    </Row>
  </footer>)
}

export default Footer