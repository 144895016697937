import React from "react"
import ProfileContainer from "../containers/ProfileContainer"



function withProfile(Wrapped) {
  function ComponentLoader(props) {
    const user = {
      loaded: props.loaded,
      error: props.error,
      profile: props.loaded ? props.model : {}
    }
    return <Wrapped {...user} {...props.originalProps} />
  }


  return (props) => <ProfileContainer originalProps={props} {...props} component={ComponentLoader} />
}


withProfile.propTypes = {}
withProfile.defaultProps = {}

export default withProfile