import config from "../../../config/config"

export const userTeamsConfig = {
  name: "userTeams",
  //used in the reducers
  uppercaseName: "USER_TEAMS",
  // useful to map objects in collection
  modelProps: ['id', 'token'],
  // can be empty (might be usefull if you need paging...)
  collectionProps: [],
  // path to your rest server
  paths: {
    item: config.user.teams + '/{id}',
    collection: '/'
  },
  headers: {
    "content-type": "application/json",
    "X-Auth-Token": "{token}"
  },
  defaultModel: [],
  fetchOnce: false
}

export default userTeamsConfig