import React from "react"
// import PropTypes from "prop-types"

function LogoUniColor({ prefix = 'placeholder-', color = "#656888", style = { height: 400 }, className }) {
  return (
    <svg style={style} className={className} viewBox="0 0 580 400" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id={`${prefix}c`} cx="1" cy="1" r="8" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} offset="0"/>
          <stop stopColor={color} offset="1"/>
        </radialGradient>
      </defs>
      <g>
        <defs>
          <linearGradient id={`${prefix}a`}>
            <stop stopColor={color} offset="0"/>
            <stop stopColor={color} offset="1"/>
          </linearGradient>
          <linearGradient id={`${prefix}b`}>
            <stop stopColor={color} offset="0"/>
            <stop stopColor={color} stopOpacity=".025686" offset="1"/>
          </linearGradient>
          <linearGradient id={`${prefix}i`} x1="-.037687" x2=".55155" y1="-.07096" y2="1.027" xlinkHref="#b"/>
          <linearGradient id={`${prefix}h`} x1=".20039" x2=".71309" y1=".13873" y2=".97512" xlinkHref="#a"/>
          <linearGradient id={`${prefix}f`} x1=".35537" x2=".73268" y1=".02127" y2=".94262" xlinkHref="#a"/>
          <linearGradient id={`${prefix}d`} x1=".15912" x2=".50001" y1=".1343" y2=".84726" xlinkHref="#a"/>
          <linearGradient id={`${prefix}e`} x1=".075141" x2=".61048" y1="-.002496" y2=".85167" xlinkHref="#b"/>
          <linearGradient id={`${prefix}g`} x1=".77948" x2=".50121" y1=".085421" y2=".42059" xlinkHref="#b"/>
        </defs>
        <g fill={`url(#${prefix}c)`}>
          <path d="m216.7 159.49a46.297 46.295 0 1 1-92.594 0 46.297 46.295 0 1 1 92.594 0z" fillRule="evenodd"
                stroke={`url(#${prefix}d)`} strokeWidth=".56617"/>
          <path
            d="m208.5 159.55c0 21.328-17.284 38.638-38.581 38.638s-38.581-17.31-38.581-38.638 17.284-38.638 38.581-38.638 38.581 17.31 38.581 38.638z"
            opacity=".37647" stroke={`url(#${prefix}e)`} strokeWidth=".67889"/>
          <path d="m209.91 163.57a29.401 31.087 0 1 1-58.803 0 29.401 31.087 0 1 1 58.803 0z" fillRule="evenodd"
                opacity=".3"/>
          <path d="m455.9 159.49a46.297 46.295 0 1 1-92.594 0 46.297 46.295 0 1 1 92.594 0z" fillRule="evenodd"
                stroke={`url(#${prefix}f)`} strokeWidth=".56617"/>
          <path
            d="m447.7 159.55c0 21.328-17.284 38.638-38.581 38.638s-38.581-17.31-38.581-38.638 17.284-38.638 38.581-38.638 38.581 17.31 38.581 38.638z"
            opacity=".37647" stroke={`url(#${prefix}g)`} strokeWidth=".67889"/>
          <path d="m428.89 163.66a29.991 31.042 0 1 1-59.982 0 29.991 31.042 0 1 1 59.982 0z" fillRule="evenodd"
                opacity=".3"/>
          <path
            d="m290.06 37.031c-63.817 0-104.06 35.242-104.06 92.87l7.8904 57.591c-16.832 8.4552-24.026 31.676-24.026 55.434 0 46.131 40.043 94.514 120.25 95.031 81.25 0.52238 120.02-48.896 120.02-95.031 0.10293-23.782-8.3511-45.858-24.026-55.434l8.1204-57.591c0-59.971-40.346-92.87-104.17-92.87h0.00463z"
            fillRule="evenodd" stroke={`url(#${prefix}h)`}/>
          <path
            d="m290 43.988c-30.467 0-54.378 8.2006-70.65 22.666-16.272 14.465-25.318 35.215-25.318 62.211l7.4749 56.665c0.43063 3.2484-1.2042 6.422-4.0992 7.9568-6.2325 3.1309-11.084 9.2284-14.468 17.843-3.3836 8.615-5.0637 19.519-5.0637 30.623 0 41.613 35.057 86.559 112.12 87.045 78.056 0.50185 112.12-45.315 112.12-87.045 0.094985-21.938-7.8673-41.288-20.014-48.708-2.6201-1.6351-4.0363-4.6562-3.6169-7.716l7.716-56.665c0-28.227-9.0478-48.782-25.318-62.934s-40.329-21.943-70.891-21.943h0.003086z"
            fillRule="evenodd" opacity=".5" stroke={`url(#${prefix}i)`}/>
          <path
            d="m250.99 101.63c-27.685 0-39.009 18.841-39.009 46.294 0 13.727 3.7765 28.012 8.493 38.577-22.988 6.2167-38.982 13.985-38.982 54.492 0 36.604 46.779 76.674 108.5 76.674s108.26-40.552 108.26-77.157c0-34.721-15.754-48.034-38.741-54.251 5.5727-11.573 8.4931-24.61 8.4931-38.336 0-27.453-11.334-45.535-39.009-46.294-16.847-0.45555-33.436 11.573-38.549 23.719-6.0321-12.146-21.279-23.264-39.468-23.719h0.00617z"
            fillRule="evenodd"/>
          <path d="m343.47 159.38a54.083 19.394 0 1 1-108.17 0 54.083 19.394 0 1 1 108.17 0z" opacity=".22"/>
          <path
            d="m367.12 217.97c0.33272 28.249-19.178 21.908-76.494 21.981-57.256 0.072145-77.793 5.5585-77.793-22.234 12.088 11.827 24.37 11.265 77.793 11.117 53.468-0.14807 66.185 1.0655 76.494-10.863v-7.72e-4z"
            opacity=".7"/>
          <path
            d="m367.12 217.88c0.33272 19.179-19.178 14.874-76.494 14.923-57.256 0.048997-77.793 3.7738-77.793-15.095 12.088 8.0301 24.37 7.6479 77.793 7.5475 53.468-0.10054 66.185 0.72338 76.494-7.3754z"/>
          <g transform="scale(7.716)">
            <path d="m35.584 18.17a2 2 0 1 1-4 0 2 2 0 1 1 4 0z" fillRule="evenodd"/>
            <path d="m34.022 17.242a1 1 0 1 1-2 0 1 1 0 1 1 2 0z" opacity=".43922"/>
          </g>
          <g transform="scale(7.716)">
            <path d="m43.584 18.17a2 2 0 1 1-4 0 2 2 0 1 1 4 0z" fillRule="evenodd"/>
            <path d="m42.013 17.166a0.9645 0.99691 0 1 1-1.929 0 0.9645 0.99691 0 1 1 1.929 0z" opacity=".43922"/>
          </g>
          <path
            d="m270.71 169.61c-6.3889 0-11.574 5.4826-11.574 12.298 0 0.084336-0.00162 0.15718 0 0.24113 5.4089-2.3103 13.333-3.9252 22.424-4.5814-1.6331-4.7066-5.8727-7.9576-10.85-7.9568v-7.72e-4z"/>
          <path
            d="m309.29 169.61c-4.978 0-9.2176 3.2505-10.85 7.9568 9.0918 0.65625 17.016 2.2711 22.424 4.5814 0.00162-0.083951 0-0.15679 0-0.24113 0-6.8148-5.1852-12.298-11.574-12.298v7.72e-4z"/>
        </g>
      </g>
    </svg>
  )
}

LogoUniColor.propTypes = {}
LogoUniColor.defaultProps = {}

export default LogoUniColor
