import React from "react"
import Welcome from "../../components/home/Welcome"
import Page from "./Page"
import withUser from "../../components/auth/HOC/withUser"

function RecipeListPage({ isLogged, children }) {
  return (
    <Page header={!isLogged && <Welcome/>} className="full-width">
      <div className="recipes">
        <section className="section list medias animated fadeIn">
          {children}
        </section>
      </div>
    </Page>
  )
}

RecipeListPage.propTypes = {}
RecipeListPage.defaultProps = {}

export default withUser(RecipeListPage)
