import React from "react"
import { Avatar, withStyles } from "@material-ui/core"

const uiStyles = () => ({
  avatar: {
    height: 24,
    width: 24
  }
})


const styles = {
  profile: {
    flexDirection: "row", display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  }
}

const UserAvatar = withStyles(uiStyles)(({ user, classes }) => {
  return <div style={styles.profile}>
    <Avatar
      style={{ marginRight: 10 }}
      alt={user.first_name} className={classes.avatar} src={user.avatar}/>
  </div>
})
UserAvatar.propTypes = {}
UserAvatar.defaultProps = {}

export default UserAvatar
