import PropTypes from "prop-types"
import { connect } from "react-redux"
import { mixerConnector } from "react-arc"
import config from "../../../config/config"
import createHOC from "../../arc/createHOC"
import withToken from "../../auth/HOC/withToken"
import { compose } from "redux"
import { ModelWithAuthContainer } from "../../auth/containers/ModelWithAuthContainer"
import { filterARCCONFIG } from "../../arc/utils"
import React from "react"


/** DONT FORGET TO :
 [x] DECLARE YOUR store : config/store.js  -> userTeams
 [x] ADD MODEL REQUIRED PROPS, in configUserTeams
 [x] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const USER_TEAMS_NAMESPACE = "USER_TEAMS"

export const configUserTeams = {
  name: "userTeams",
  uppercaseName: USER_TEAMS_NAMESPACE,
  modelProps: ['userId'],
  collectionProps: [],
  paths: {
    item: config.user.teams,
    add:config.recipe.addToTeam,
    remove:config.recipe.removeFromTeam,
  },
  headers: {
    "content-type": "application/json",
    "X-Auth-Token": "{token}"
  },
  defaultModel: [],
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100
}

const types = {
  userId: PropTypes.string
}

function addRecipeToTeam({recipeId, teamId}, ARCConfig) {
  return fetch(ARCConfig.paths.add, {
    headers: ARCConfig.headers,
    method:'post',
    body: JSON.stringify({recipeId, teamId})
  }).then(r=>r.json())
}

function removeRecipeFromTeam({recipeId, teamId}, ARCConfig) {
  return fetch(ARCConfig.paths.remove, {
    headers: ARCConfig.headers,
    method:'post',
    body: JSON.stringify({recipeId, teamId})
  }).then(r=>r.json())
}

export class UserTeamsContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configUserTeams
  }
  static propTypes = types

  addRecipeToTeam = ({recipeId, teamId}) => {
    return addRecipeToTeam({recipeId, teamId}, this.ARCConfig)
  }

  removeRecipeFromTeam = ({recipeId, teamId}) => {
    return removeRecipeFromTeam({recipeId, teamId}, this.ARCConfig)
  }

  render() {
    const Component = this.props.component
    return <Component {...filterARCCONFIG(this.props)}
                      error={this.getError()}
                      fetch={this.fetch}
                      isNew={this.isNew()}
                      loaded={this.isLoaded()}
                      extendedProps={{
                        addRecipeToTeam:this.addRecipeToTeam,
                        removeRecipeFromTeam:this.removeRecipeFromTeam
                      }}
                      model={this.isLoaded() ? this.getModel() : null}/>
  }

}

const connector = mixerConnector(connect, configUserTeams)
export const UserTeamsContainer = compose(withToken, connector)(UserTeamsContainerBase)

const withUserTeams = createHOC({
  Container: UserTeamsContainer,
  ARCConfig: configUserTeams
})
withUserTeams.propTypes = types

withUserTeams.defaultProps = {}

export default withUserTeams