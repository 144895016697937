import React from "react"
import { Column, Row } from "../../../../layout/grid"


const TAB_TYPES_HEIGHT = "30px"
const styles = {
  editorTypeTitle: {
    backgroundColor: "#343434",
    lineHeight: TAB_TYPES_HEIGHT
  },
  title: {
    paddingLeft: 40,
    fontSize: 10,
    fontWeight: 800,
    color: "#DDD",
    textTransform: "uppercase",
    fontFamily: "'Baloo Bhaina 2', Helvetica, Roboto,  Arial, sans-serif"
  },
  type: {
    paddingRight: 20,
    textAlign: "right",
    fontSize: 10,
    color: "#999",
    fontFamily: "'Baloo Bhaina 2', Helvetica, Roboto,  Arial, sans-serif"
  },
  toggleIcon: {

  },
  toggleIconStateActive: {
    //transform: "rotate(90deg)"
  }
}

const EditorTypeTitle = ({ title, type, isDefaultState, state = true, toggleState }) => {

  const expand = "fa fa-expand"
  const collapse = "fa fa-compress"
  const icon = isDefaultState ? expand : (state ? collapse : expand)
  return (
    <div style={styles.editorTypeTitle}>
      <Row className="no-gutters">
        <Column forceClassName='col' style={styles.title}>
          <div className="button-toggle-sidebar-icon tab-icon"
               onClick={toggleState}
               style={{
                 ...styles.toggleIcon,
                 ...(!isDefaultState && state ? styles.toggleIconStateActive : {})
               }}>
            <span className={icon} style={{ fontSize: 16 }}/>
          </div>

          {title}
        </Column>
        {(isDefaultState || state) && <Column className={"animated fadeIn"} size={3} style={styles.type}>
          {type}
        </Column>}

      </Row>
    </div>
  )
}
EditorTypeTitle.propTypes = {}
EditorTypeTitle.defaultProps = {}

export default EditorTypeTitle
