import { mixerConnector } from "react-arc"
import PropTypes from "prop-types"
import connect from "react-redux/es/connect/connect"
import FormModelWithAuthContainer from "../../auth/containers/FormModelWithAuthContainer"
import { addToast } from "re-toaster"
import React  from "react"
import FieldComponent from "../../../layout/inputs/FieldComponent"
import CalloutComponent from "../../../layout/error/CalloutComponent"
import Loader from "../../../layout/loader/Loader"
import userTeamsConfig from "../config/userTeamsConfig"
import FieldWrapper from "../../../layout/inputs/FieldWrapper"
import withToken from "../../auth/HOC/withToken"
import { leaveTeam, popError, popSuccess } from "../../team/actions/teamActions"
import TeamsBoard from "./TeamsBoard"


const Field = ({ model, prop, placeholder, changeProp }) => {
  return (
    <FieldWrapper>
      <FieldComponent disabled={!changeProp} variant={"outlined"} name={prop} placeholder={placeholder}
                      onChange={changeProp}
                      value={model[prop] || ""}/>
    </FieldWrapper>
  )
}
Field.propTypes = {
  model: PropTypes.object,
  prop: PropTypes.string,
  placeholder: PropTypes.string,
  changeProp: PropTypes.func
}



class UserTeamsEdit extends FormModelWithAuthContainer {

  static defaultProps = {
    ARCConfig: userTeamsConfig
  }

  constructor(props) {
    super(props)
    this.state = {
      mode: "info",
      allowNotification: false,
      loading: false,
      error: false
    }
  }

  prepareSave = () => {
    this.setState({
      allowNotification: true
    })
    this.save()
  }

  onSave(created) {
    if (this.state.allowNotification) {
      this.props.dispatch(addToast({ type: "success", icon: "fa fa-check", message: "Profile Updated !" }))
      this.setState({
        allowNotification: false
      })
    }
  }

  leaveTeam = (id) => {
    if (this.state.pending) return
    this.setState({ error: null, loading: true })
    leaveTeam({ id, token: this.props.token })
      .then(r => {
        this.setState({ error: null, pending: false })
        if (r.success === false) return Promise.reject(r)
        popSuccess(r.message)
        this.fetch(this.getParams())
      }).catch(r => {
      this.setState({ error: r.message || r, pending: false })
      popError(r.message || r)
    })
  }

  refresh = () => {
    return this.fetch(this.getParams())
  }

  render() {
    const error = this.getError()
    const loaded = this.isLoaded()

    if (error) return <CalloutComponent error={error}/>
    if (!loaded) return <Loader/>

    const teams = this.getModel()

    return (<TeamsBoard teams={teams} leaveTeam={this.leaveTeam} refresh={this.refresh} />)
  }
}


export default withToken(mixerConnector(connect, userTeamsConfig)(UserTeamsEdit))

// export default mixerConnector(connect, userTeamsConfig, (store) => ({
//   token: store.auth.user.token
// }))(UserTeamsEdit)