import { useEffect, useRef } from "react"
import { extensionIsAvailable, updateAll } from "../actions/extensionAction"
import extensionActions from "../../recipe/components/extension/extension-actions"



function ChimpExtensionProvider({ children, store }) {

  const bodyRef = useRef(window.document.body)
  const {dispatch} = store

  useEffect(() => {
    const ref = bodyRef.current
    ref.addEventListener('chimp-sync-db', (e) => {
      dispatch(updateAll({collection: e.detail}))
    })

    extensionActions.syncData(({ data }) => {
      dispatch(updateAll({collection: data}))
      dispatch(extensionIsAvailable())
    })
    return () => {
      ref.removeEventListener('chimp-sync-db', null)
    }
  }, [bodyRef, dispatch])




  return children
}

ChimpExtensionProvider.propTypes = {}
ChimpExtensionProvider.defaultProps = {}

export default ChimpExtensionProvider
