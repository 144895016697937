import React, { useState } from "react"
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  withStyles
} from "@material-ui/core"
import { NotificationsActive } from "@material-ui/icons"
import { Column, Row } from "../../../layout/grid"
import useItemPicker from "../../hooks/useItemPicker"
import Button from "@material-ui/core/Button"
import withTeamInvitation, { configTeamInvitation } from "../arc/teamInvitation"
import withARCLoader from "../../auth/HOC/withARCLoader"
import withConfirmDialog from "../../../layout/confirm/withConfirmDialog"
import { useARC } from "react-arc/lib/hooks/useARC"
import withToken from "../../auth/HOC/withToken"
import { interpolate } from "react-arc/lib/utils"
import CalloutComponent from "../../../layout/error/CalloutComponent"
import Loader from "../../../layout/loader/Loader"
import { popSuccess } from "../../team/actions/teamActions"
import MemberList from "./MemberList"
import UserAvatar from "./UserAvatar"
import Username from "./Username"


const uiStyles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
})

const styles = {
  row: {
    height: 50,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  profile: {
    flexDirection: "row", display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  }
}




const Team = ({ team, refresh, leaveTeam }) => {
  return (<div key={team.id} className="animated fadeInUpShort" style={{ animationDuration: "300ms", padding: 20 }}>
    <h4 style={{ marginBottom: 20 }}>{team.name}</h4>
    <MemberList team={team} refresh={refresh} leaveTeam={leaveTeam}/>
  </div>)
}


const TeamListItem = withStyles(uiStyles)(({ onClick, team, selected }) => {
  return (
    <React.Fragment>
      <ListItem selected={selected} button onClick={() => onClick(team)}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#4B659F" }}>
            <strong>{team.name[0]}</strong>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={team.name}
          secondary={team.members.length + " members"}
        />
      </ListItem>
    </React.Fragment>
  )
})

const invitationFeedbackConfig = {
  ...configTeamInvitation, modelProps: [
    ...configTeamInvitation.modelProps, "invitation"
  ]
}

const useInvitationFeedback = ({token, ARCConfig}) => {
  const { arc } = useARC({ ARCConfig, props: {token} })
  const config = arc.arc.config
  const getHeaders = ({invitation}) => {
    return arc.arc.applyHeaders(config.headers, {invitation, token})
  }
  const getUrl = ({invitation}) => {
    return interpolate(config.paths.accept, arc.arc.extractParams({invitation}))
  }
  const accept = ({invitation}) => {
    const url = getUrl({invitation})
    return fetch(url,{
      method: 'put',
      headers: getHeaders({invitation})
    })
  }
  const decline = ({invitation}) => {
    const url = getUrl({invitation})
    return fetch(url,{
      method: 'delete',
      headers: getHeaders({invitation})
    })
  }
  return {accept, decline}
}

const Invitations = withToken(withConfirmDialog(({ invitations = [], setConfirm, token, refresh, refreshTeams }) => {
  const [state, setState] = useState({error: null, loading: false})
  const {accept, decline} = useInvitationFeedback({token, ARCConfig: invitationFeedbackConfig})
  const handleState = (promise) => {
    return promise.then(r=>r.json()).then(() => {
      setState({...state, loading: false})
      refreshTeams()
      refresh()
      return promise
    }).catch((error) => {
      setState({...state, loading: false, error: error})
      return promise
    })
  }

  const handleRejection = (invitation) => {
    if (state.loading) return
    setState({...state, error: null, loading: true})
    handleState(decline({ invitation: invitation.id }), invitation)
      .then(() => popSuccess(`You declined the invitation from ${invitation.team_name} team`))
  }

  const handleRejectionWithConfirmation = (invitation) => {
    return setConfirm({
      title: "Decline invitation ?",
      content: "Please confirm",
      callback: () => handleRejection(invitation)
    })
  }
  const handleAccept = (invitation) => {
    if (state.loading) return
    setState({...state, error: null, loading: true})
    handleState(accept({invitation: invitation.id}), invitation)
      .then(() => popSuccess(`you joined the ${invitation.team_name} team`))
  }

  return (
    <div className="animated fadeInUpShort" style={{ animationDuration: "300ms", padding: 20 }}>
      <h4 style={{ marginBottom: 20 }}>Invitations to join teams</h4>
      {state.error && <CalloutComponent error={state.error} />}
      {state.loading && <div style={{position:'relative'}}>
        <Loader style={{position: 'absolute', top: -40, right: 20}} />
      </div>}
      <div className="px-list" style={{ marginBottom: 20 }}>
        {invitations.length === 0 && (
          <div style={{padding: 20}}>
              <p>No new invitations :(</p>
            </div>
          )}
        {invitations.map(invitation => (
          <Row style={styles.row} key={invitation.id} className="no-gutters animated fadeIn px-list-item">
            <Column size={3}>
              <strong>
                {invitation.team_name}
              </strong>
            </Column>
            <Column size={6}>
              <div style={styles.profile}>
                <UserAvatar user={invitation.by}/>
                <Username user={invitation.by}/> invited you to join {invitation.team_name} team.
              </div>
            </Column>
            <Column size={3}>
              <Button onClick={() => handleRejectionWithConfirmation(invitation)} size={"small"}
                      style={{ marginRight: 10 }}>Reject</Button>
              <Button onClick={() => handleAccept(invitation)} size={"small"} variant={"contained"} disableElevation
                      color="primary">Accept</Button>
            </Column>
          </Row>
        ))}
      </div>
    </div>
  )
}))


const TeamsBoard = ({ teams = [], leaveTeam, refresh, model: invitations, fetch }) => {


  const hasInvitations = invitations.length > 0

  //TODO: Use ID instead
  const [currentIndex, setCurrentIndex] = useItemPicker(hasInvitations ? -1 : 0)


  const refreshInvitations = () => fetch()
  const handleLeaveTeam = (team) => leaveTeam(team.id)

  return (
    <div className="animated fadeIn">
      <Row className="no-gutters"  style={{minHeight: 300}}>
        <Column size={3} style={{ borderRight: "1px solid #F0F0F0" }}>
          <List style={{padding: 0}}>
            <ListItem selected={currentIndex === -1} button onClick={() => setCurrentIndex(-1)}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#FFF" }}>
                  <NotificationsActive style={{ color: hasInvitations ? "#E33" : "#FC0" }}/>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={"Invitations"}
                secondary={`${invitations.length} awaiting invitation${invitations.length > 1 ? "s" : ""} `}
              />
            </ListItem>
            <Divider/>

            {teams.map((team, index) => (
              <TeamListItem
                selected={currentIndex === index}
                team={team}
                key={team.id}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </List>
        </Column>
        <Column size={9} style={{ fontSize: "14px" }}>
          {teams.length === 0 && currentIndex === 0 && (
            <div className="text-center" style={{padding: 20}}>
              <h5>No teams to display</h5>
              <p className="text-muted">...yet !</p>
            </div>
          )}
          {currentIndex === -1 && (
            <Invitations refreshTeams={refresh} refresh={refreshInvitations} invitations={invitations}/>
          )}
          {teams[currentIndex] && <Team team={teams[currentIndex]} leaveTeam={handleLeaveTeam} refresh={refresh}/>}
        </Column>
      </Row>


    </div>
  )
}

TeamsBoard.propTypes = {}
TeamsBoard.defaultProps = {}

export default withTeamInvitation(withARCLoader(TeamsBoard))
