import PropTypes from "prop-types";
import { addToast } from "re-toaster";
import React, { useEffect, useRef } from "react"
import FieldComponent from "../../../layout/inputs/FieldComponent";
import CalloutComponent from "../../../layout/error/CalloutComponent";
import { Button } from "@material-ui/core";
import profileConfig from "../config/profileConfig";
import FieldWrapper from "../../../layout/inputs/FieldWrapper";
import withToken from "../../auth/HOC/withToken";
import { useARC } from "react-arc/lib/hooks/useARC";
import useFormState from "../../hooks/useFormState";
import store from "../../../config/store";
import { pick } from "../../../utils/format";
import Loader from "../../../layout/loader/Loader";

const Field = ({ model, prop, placeholder, changeProp }) => {
  return (
    <FieldWrapper>
      <FieldComponent
        disabled={!changeProp}
        variant={"outlined"}
        name={prop}
        placeholder={placeholder}
        onChange={changeProp}
        value={model[prop] || ""}
      />
    </FieldWrapper>
  );
};
Field.propTypes = {
  model: PropTypes.object,
  prop: PropTypes.string,
  placeholder: PropTypes.string,
  changeProp: PropTypes.func
};

const ProfileEditor = withToken(({ token, id }) => {
  const { error, loading, loaded, response, arc } = useARC({
    ARCConfig: profileConfig,
    props: {
      token,
      id
    }
  });

  const [form, setForm, setFullForm] = useFormState({
    username: "",
    first_name: "",
    last_name: "",
    email: ""
  });

  const initForm = useRef(setFullForm)

  useEffect(() => {
    if (response && loaded) {
      const data = pick(response, ["username", "first_name", "last_name", "email"]);
      initForm.current(data);
    }
  }, [response, loaded, initForm]);

  const handleSave = () => {
    arc
      .update({ body: form })
      .then(() => {
        store.dispatch(
          addToast({
            type: "success",
            icon: "fa fa-check",
            message: "Profile Updated !"
          })
        );
      })
      .catch(() => {
        store.dispatch(
          addToast({
            type: "error",
            icon: "fa fa-warning",
            message: "Some Error happened !"
          })
        );
      });
  };

  if (!loaded) return <Loader />;

  return (
    <div className="animated fadeIn">

      {loading && <Loader />}
      {error && <CalloutComponent error={error} />}

      <div style={{ padding: "20px 40px" }}>
        <Field placeholder={"Username"} model={form} prop={"username"} />
        <Field
          placeholder={"First Name"}
          changeProp={setForm}
          model={form}
          prop={"first_name"}
        />
        <Field
          placeholder={"Last Name"}
          changeProp={setForm}
          model={form}
          prop={"last_name"}
        />
        <Field placeholder={"Email"} model={form} prop={"email"} />

        <FieldWrapper>
          <Button
            variant={"contained"}
            color={"primary"}
            style={{ width: "100%" }}
            onClick={handleSave}
          >
            Update
          </Button>
        </FieldWrapper>
      </div>
    </div>
  );
});

export default ProfileEditor;
