import React from "react"
import { Button } from "@material-ui/core"
import { Column, Row } from "../../../../layout/grid"
import "./recipe-filter.scss"
import navigate from "../../../../singletons/navigate"
import DropDownMenu from "../../../../layout/dropdown/DropDownMenu"
import withUser from "../../../auth/HOC/withUser"
import withUserTeams from "../../../user/arc/UserTeams"

const styles = {
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  button: {
    color: "#444",
    margin: "0 5px",
    padding: "6px 18px"
    //padding: '0 20px',
    //textTransform: 'none'
  },
  active: {
    backgroundColor: "#FFF"
  }
}


const FILTERS = [
  {
    id: "all",
    primaryText: "All",
    navigate: () => navigate("/recipes")
  },
  {
    id: "css",
    primaryText: "With styles only",
    navigate: () => navigate("/type/styles")
  },
  {
    id: "js",
    primaryText: "With scripts only",
    navigate: () => navigate("/type/scripts")
  }
]


const UserTeamDropDown = withUser(withUserTeams((({ model = [], value }) => {
  const teams = Array.isArray(model) ? model : []
  const options = [{
    id: "All",
    primaryText: "All",
    value: "all",
    path: "/recipes"
  }].concat(teams.map(team => ({
    id: team.id,
    primaryText: team.name,
    value: team.id,
    path: `/team/${team.id}`
  })))
  return <DropDownMenu value={value} options={options} onChange={({ path }) => navigate(path)}/>
})))


const HorizontalMenu = ({ type }) => {
  const style = (filter) => ({
    ...styles.button,
    ...(type === filter.id ? styles.active : {})
  })

  return FILTERS.map(filter => (
    <Button
      onClick={filter.navigate}
      key={filter.id}
      style={style(filter)}
    >{filter.primaryText}</Button>
  ))
}


function RecipeFilter({ teamId, type = 'all' }) {
  const dropDownValue = teamId || "all"
  return (
    <div className="recipe-filters">
      <Row className="no-gutters">
        <Column size={3}>
          <UserTeamDropDown value={dropDownValue}/>
        </Column>
        <Column forceClassName={"col"} style={styles.center}>
          <HorizontalMenu type={type}/>
        </Column>
        <Column size={3}>

        </Column>
      </Row>
    </div>
  )
}

RecipeFilter.propTypes = {}
RecipeFilter.defaultProps = {}

export default RecipeFilter
