import { applyMiddleware, createStore, compose, combineReducers } from "redux"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"
//import { routerReducer, routerMiddleware } from "react-router-redux"

import { connectRouter, routerMiddleware } from 'connected-react-router'

import history from "../singletons/history"
import auth from "../components/auth/reducers/auth"
import appClient from "../components/client/reducers/appClient"
import { toaster } from "re-toaster"

import { mixerStore } from "react-arc"
import fieldState from "../layout/px-status/reducers/fieldState"
import recipeConfig from "../components/recipe/recipeConfig"
import signupConfig from "../components/auth/config/signupConfig"
import { profileConfig } from "../components/user/config/profileConfig"
import userTeamsConfig from "../components/user/config/userTeamsConfig"
import {
  configTeamRecipeCollection
} from "../components/recipe/arc/teamRecipeCollection"
import { configRecipesCollection } from "../components/recipe/arc/recipesCollection"
import { configRecipeByType } from "../components/recipe/arc/recipeByType"
import { configTeamInvitation } from "../components/user/arc/teamInvitation"
import extension from "../components/extension/reducers/extension"


const middleware = applyMiddleware(promise(), thunk, routerMiddleware(history))
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const enhancer = composeEnhancers(middleware)

const reducers = {
  toaster,
  appClient,
  auth,
  fieldState,
  recipe:mixerStore({config: recipeConfig}),
  signup:mixerStore({config: signupConfig}),
  profile:mixerStore({config: profileConfig}),
  userTeams:mixerStore({config: userTeamsConfig}),
  teamInvitation:mixerStore({config: configTeamInvitation}),
  recipesCollection:mixerStore({config: configRecipesCollection}),
  recipeByType:mixerStore({config: configRecipeByType}),
  teamRecipeCollection:mixerStore({config: configTeamRecipeCollection}),
  router: connectRouter(history),
  extension
}

const store =
  Object.keys(reducers).length > 0
    ? createStore(combineReducers(reducers), enhancer)
    : createStore(enhancer)

export default store
