import React from 'react'
import {loadClient} from '../actions/clientActions'
import {connect} from 'react-redux'

//TODO
/** MERGE THIS INTO GITHUB CONNECTORS ETC **/

class ClientComponent extends React.Component {
    render() {
        return this.props.children
    }

    componentDidMount() {
        this.props.dispatch(loadClient())
    }
}


export default connect((store) => {
    return {
        client: store.appClient.client
    }
})(ClientComponent)