import React from "react"
import { Divider } from "@material-ui/core"
import Loader from "../../../../layout/loader/Loader"
import CalloutComponent from "../../../../layout/error/CalloutComponent"
import { useARC } from "react-arc/lib/hooks/useARC"
import registrationConfirmationConfig from "../../config/registrationConfirmationConfig"
import FieldWrapper from "../../../../layout/inputs/FieldWrapper"
import FieldComponent from "../../../../layout/inputs/FieldComponent"
import useFormState from "../../../hooks/useFormState"
import Button from "@material-ui/core/Button"
import { Column, Row } from "../../../../layout/grid"
import navigate from "../../../../singletons/navigate"


const styles = {
  center: {
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex"
  },
  p: {
    margin: 0
  }
}


const Success = () => {
  return <div  className="animated fadeIn text-center" style={{ padding: "20px 40px" }}>
    <div style={{padding: 40}}>
      <span style={{fontSize: 40}} aria-label="success" role="img">🎊</span>
    </div>
    <h4>
      Account activated
    </h4>
    <p>You can now sign in using your username and password !</p>

    <div style={{padding:20}}>
      <Button
        variant={"contained"}
        disableElevation
        color="primary"
        onClick={() => navigate('/sign-in')}>Sign in</Button>
    </div>
  </div>
}

function RegistrationCompletion({ token, email }) {
  const { error, loading, loaded, response, arc } = useARC({
    ARCConfig: registrationConfirmationConfig,
    props: {}
  })


  const [form, setForm] = useFormState({
    username:  email.split("@").shift() , //||,
    password: "",
    passwordConfirm: ""
  })

  const missingFields = Object.keys(form).reduce((s, prop) => {
    if (!form[prop]) return s.concat(prop)
    return s
  }, [])

  const passwordsDoNotMatch = form.password !== form.passwordConfirm

  const hasMessage = missingFields.length > 0 || (missingFields.length === 0 && passwordsDoNotMatch)


  const handleSubmit = () => {
    if (hasMessage) return
    arc.create({
      props: {}, params: {}, body: {
        token: token,
        email: email,
        username: form.username,
        password: form.password,
        passwordConfirm: form.passwordConfirm,
      }
    })

  }

  return (
    <div>

      {loading && (<div  className="animated fadeIn" style={{fontSize: 40, padding: 40}}>
        <Loader/>
      </div>)}

      {!!error && (
        <div className="animated fadeIn">
          <CalloutComponent error={error} title={error}/>
        </div>
      )}

      {loaded && response && response.complete && (
        <Success />
      )}

      {(!response || !response.complete) && (
        <div>
          <p>Last step before enjoying chimp!</p>
          <p>Please choose a Username and a Password.</p>

          <FieldWrapper>
            <FieldComponent
              variant={"outlined"}
              name={"username"}
              placeholder={"Username"}
              onChange={setForm}
              value={form.username || ""}
            />
          </FieldWrapper>

          <FieldWrapper>
            <FieldComponent
              variant={"outlined"}
              type={"password"}
              placeholder={"Password"}
              name={"password"}
              onChange={setForm}
              value={form.password || ""}
            />
          </FieldWrapper>

          <FieldWrapper>
            <FieldComponent
              variant={"outlined"}
              type={"password"}
              placeholder={"Confirm Password"}
              name={"passwordConfirm"}
              onChange={setForm}
              value={form.passwordConfirm || ""}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Row>
              <Column size={6} style={styles.center}>
                {hasMessage && (
                  <p style={styles.p} className="animated fadeIn text-muted font-italic">
                    {missingFields.length > 0 && "Please fill all the form fields"}
                    {missingFields.length === 0 && passwordsDoNotMatch && "Passwords do not match"}
                  </p>
                )}

              </Column>
              <Column size={6} className="text-right">
                <Button
                  onClick={handleSubmit}
                  disabled={hasMessage}
                  color={"primary"}
                  disableElevation variant={"contained"}
                >
                  Start !
                </Button>
              </Column>
            </Row>
          </FieldWrapper>
        </div>

      )}

    </div>

  )
}

function RegistrationConfirmation({ token, email }) {
  const { error, loading, loaded, response } = useARC({
    ARCConfig: registrationConfirmationConfig,
    props: {
      token,
      email
    }
  })

  return (
    <div>
      <div style={{ padding: "20px 40px" }}>
        <h4>Registration Confirmation</h4>
      </div>
      <Divider/>
      {loading && (<div  className="animated fadeIn" style={{fontSize: 40, padding: 40}}>
       <Loader/>
      </div>)}

      {!!error && (
        <div className="animated fadeIn">
          <CalloutComponent error={error}/>
        </div>
      )}

      {loaded && response && (
        <div  className="animated fadeIn" style={{ padding: "20px 40px" }}>
          {response.complete ? (
            <Success />
          ) : (
            <RegistrationCompletion token={token} email={email}/>
          )}

        </div>
      )}

    </div>
  )
}

RegistrationConfirmation.propTypes = {}
RegistrationConfirmation.defaultProps = {}

export default RegistrationConfirmation
