import React from "react"

const styles = {
  container: {
    paddingBottom: 20
  },
  title: {
    color: '#FFF'
  },
  subtitle: {
    color: '#F0F0F0'
  }
}

function PageTitle({ title= '', subtitle="" }) {
  return (
    <div style={styles.container}>
      {title && ( <h1 style={styles.title}>{title}</h1>)}
      {subtitle && (<p style={styles.subtitle}>{subtitle}</p>)}
    </div>
  )
}

PageTitle.propTypes = {}
PageTitle.defaultProps = {}

export default PageTitle
