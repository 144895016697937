import { loadSession } from "../core/configLess"

export function reloadSessionAbstract(dispatch) {
  dispatch({ type: "AUTH_RELOAD_SESSION", payload: {} })
  return loadSession().then((response) => {
    const payload = {
      profile: { ...response.profile },
      expires: response.expires,
      token: response.token,
      isAdmin: response.isAdmin
    }
    dispatch({ type: "AUTH_RELOAD_SESSION_SUCCESSFUL", payload })
    return payload
  }).catch(error => {
    //console.log(error)
    dispatch({ type: "AUTH_RELOAD_SESSION_REJECTED", payload: {} })
    return Promise.resolve()
  })
}

export function reloadSession(params) {
  return function(dispatch) {
    dispatch({ type: "AUTH_RELOAD_SESSION", payload: params })
    loadSession().then((response) => {
      const payload = {
        profile: { ...response.profile },
        expires: response.expires,
        token: response.token,
        isAdmin: response.isAdmin
      }
      dispatch({ type: "AUTH_RELOAD_SESSION_SUCCESSFUL", payload })
    }).catch(error => {
      //console.log(error)
      dispatch({ type: "AUTH_RELOAD_SESSION_REJECTED", payload: {} })
    })
  }
}

