import { withARC } from "react-arc"
import { ModelWithAuthContainer } from "../../auth/containers/ModelWithAuthContainer"
import withToken from "../../auth/HOC/withToken"
import createHOC from "../../arc/createHOC"
import config from "../../../config/config"


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> userRole
 [ ] ADD MODEL REQUIRED PROPS, in configUserRole
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const USER_ROLE_NAMESPACE = "USER_ROLE"

export const configUserRole = {
  name: "userRole",
  uppercaseName: USER_ROLE_NAMESPACE,
  modelProps: ['team','role', 'username'],
  collectionProps: [],
  paths: {
    item: config.team.grantRole
  },
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-token": "{token}",
  },
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100
}

const types = {}


function setUserRole({username, role, team}, ARCConfig) {
  return fetch(ARCConfig.paths.add, {
    headers: ARCConfig.headers,
    method:'post',
    body: JSON.stringify({username, role})
  }).then(r=>r.json())
}

export class UserRoleContainerBase extends ModelWithAuthContainer {
  static propTypes = types
  // static defaultProps = {} //IF YOU NEED IT :)
  constructor(props) {
    super(props);
    this.actions.standAloneFetchOne = setUserRole
  }
}

export const UserRoleContainer = withToken(withARC(configUserRole)(UserRoleContainerBase))

const withUserRole = createHOC({
  Container: UserRoleContainer,
  ARCConfig: configUserRole
})

export default withUserRole