import React from "react"
import DialogContent from "@material-ui/core/DialogContent"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "@material-ui/core/Button"


const InviteDialog = ({title= 'Invite Someone', children, labelReject = 'close', open, onClose}) => {

  return  <Dialog
    disableBackdropClick
    disableEscapeKeyDown
    aria-labelledby="confirmation-dialog-title"
    open={open}

    maxWidth="sm" fullWidth>
    <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
    {!!children && (<DialogContent dividers>
      {children}
    </DialogContent>)}
    <DialogActions>
      <Button autoFocus onClick={onClose} color="primary">
        {labelReject}
      </Button>

    </DialogActions>
  </Dialog>
}

InviteDialog.propTypes = {}
InviteDialog.defaultProps = {}

export default InviteDialog
