import React, { PureComponent } from "react"
import Recipe from "../../components/recipe/components/Recipe"
import Page from "../../layout/page/Page"


class RecipePage extends PureComponent {
  render() {
    const recipe_id = this.props.match.params.id
    return (
      <Page className="full-width with-editor">
        <Recipe
          creation={recipe_id === "add"}
          id={recipe_id === "add" ? undefined : recipe_id}
        />
      </Page>
    )
  }

}

RecipePage.propTypes = {}
RecipePage.defaultProps = {}

export default RecipePage
