import React from "react"
import { Avatar } from "@material-ui/core"

function AuthorAndDate({userInfo, dateText}) {
  return (
    <div style={{lineHeight: "58px" }}>
      <Avatar style={{ margin: "10px", float: "left" }} src={userInfo.avatar}/>
      <div style={{ verticalAlign: "middle", display: "inline-block", "lineHeight": "20px" }}>
        <strong>{userInfo.author_name}</strong><br/>
        <span className="text-muted" style={{fontSize: '11px'}}>{dateText}</span>
      </div>
      <div style={{ clear: "both" }}/>
    </div>
  )
}

AuthorAndDate.propTypes = {}
AuthorAndDate.defaultProps = {}

export default AuthorAndDate
