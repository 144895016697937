import React from "react"
import PropTypes from "prop-types"
import "./page.scss"

function Page(props) {
  const className = "page animated fadeIn " + props.className
  return (<div className={className} style={props.style}>
    <div className="page-content">
      {!!props.header && (<div className="page-header-fullWidth">
        {props.header}
      </div>)}
      {props.children}
    </div>
  </div>)
}

Page.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  header: PropTypes.node
}
Page.defaultProps = {
  style: {},
  className: ""
}

export default Page
