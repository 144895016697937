import React, {Component} from "react";
import FieldComponent from "../inputs/FieldComponent";
import {Button, withStyles} from "@material-ui/core";
import Loader from "../loader/Loader";


const styles = {
    button: {
        root: {
            display: "block",
            width: "100%"
        }
    }
};

function RowContainer(props) {
    return (<div style={props.style || {}}> {props.children}</div>);
}

export class LoginComponent extends Component {


    onChange = (prop, v, e) => {
        const data = {};
        data[prop] = v;
        const temp = Object.assign({}, this.state.user, data);
        this.setState({user: temp});
    };
    submit = () => {
        // do something here
        //logon(this.state.user.name, this.state.user.password)
        if (this.props.onChange) this.props.onChange(this.state.user.name, this.state.user.password);
    };

    constructor(props) {
        super(props);
        this.state = {
            user: {
                name: props.name || "",
                password: props.password || ""
            }
        };
    }

    render() {
        const inputStyle = {width: "100%"};
        return (
          <div className={this.props.className} style={{...this.props.style}}>
              <div className="form-horizontal">
                  {this.props.error ? <RowContainer name="" className="animated fadeIn">
                      <div style={{
                          padding: "20px",
                          backgroundColor: "rgba(255, 184, 50, 0.3)"
                      }}>{this.props.error.data}</div>
                  </RowContainer> : null}
                  <RowContainer name="Name" style={{padding: "10px 0"}}>
                      <FieldComponent disableUnderline={false} style={inputStyle} value={this.state.user.name}
                                      onChange={this.onChange}
                                      variant="outlined"
                                      type="text" name="name" placeholder="Username"/>
                  </RowContainer>
                  <RowContainer name="Password" style={{padding: "10px 0"}}>
                      <FieldComponent disableUnderline={false} onEnterKey={this.submit} style={inputStyle}
                                      value={this.state.user.password}
                                      variant="outlined"
                                      onChange={this.onChange} type="password" name="password"
                                      placeholder="Password"/>
                  </RowContainer>
                  <RowContainer className={""} name="" style={{marginTop: 30}}>
                      <Button variant="contained" style={styles.button.root} disabled={this.props.pending}
                              onClick={this.submit}
                              color="primary">
                          {this.props.pending ? <Loader/> : "Sign in"}
                      </Button>
                  </RowContainer>
              </div>
          </div>
        );
    }

}

export default withStyles(styles)(LoginComponent);