import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import config from "../../../config/config"
//import logo from "../../../assets/logo-chimp-fr.svg"
import LogoUniColor from "../../../assets/icons/LogoUniColor"
import Loader from "../../../layout/loader/Loader"


const styles = {
  image: {
    //minHeight: 400,
    //border: "1px solid #DEDEDE",
    //margin: "10px 0",
    //backgroundSize: "100% auto",
    //backgroundPosition: "50% 50%",
    //backgroundRepeat: "no-repeat",
    //borderRadius: 3,
    //lineHeight: "400px",
    //backgroundColor: "#FFF",
    textAlign: "center"
  }
}

const getImageSize = (imageElement) => {
  return {
    width: imageElement.offsetWidth, height: imageElement.offsetHeight
  }
}

const ImageLoader = ({ url = "", onLoaded }) => {
  const [state, setState] = useState({ loaded: false })
  const image = useRef()

  // useEffect(() => {
  //   console.log("loading")
  // }, [])
  useEffect(() => {
    if (state.loaded === true && onLoaded) {
      onLoaded(getImageSize(image.current))
    }
  }, [state, onLoaded])
  if (!url) return null

  return (<img
    style={{ position: "fixed", top: -9999, left: -9999, opacity: 0 }}
    alt="recipe"
    onLoad={() => setState({ loaded: true })}
    ref={image}
    src={url}
  />)
}

export const ImagePlaceHolder = ({style, color}) => {
  return (<div className="media-wrapper empty" style={{ ...styles.image, ...style }}>
    <LogoUniColor color={color}/>
  </div>)
}

const RecipeImage = ({ recipe, size }) => {
  const hasMedia = !!recipe.media
  const [image, setImageSize] = useState({ height: 0, width: 0, loaded: false })
  const [wrapperSize, setWrapperSize] = useState({ height: 0, width: 0 })
  const wrapper = useRef()
  const source = config.images + "/images/" + (size === "large" ? "original/" : "") + recipe.media

  useEffect(() => {
    if (wrapper && wrapper.current) {
      setWrapperSize(getImageSize(wrapper.current.parentElement))
    }
  }, [wrapper, image])

  if (!hasMedia) return <ImagePlaceHolder
    color={'#656888'}
    style={{
    textAlign: 'center',
    height: '80%',
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    boxShadow: '0 0 3px rgba(0,0,0,.3)'
  }}/>

  const imageStyle = image.width >= image.height ? {
    width: wrapperSize.width * .8,
    height: "auto"
  } : {
    height: wrapperSize.height * .8,
    width: "auto"
  }



  if (image.loaded) return (
    <div ref={wrapper} className="media-wrapper animated fadeIn" style={{ ...styles.image }}>
      <img style={imageStyle} src={source} alt='recipe display'/>
    </div>
  )



  return <React.Fragment>
    <Loader />
    <ImageLoader url={source} onLoaded={(props) => setImageSize({ ...props, loaded: true })}/>
  </React.Fragment>
}

RecipeImage.propTypes = {
  recipe: PropTypes.object,
  size: PropTypes.oneOf(["normal", "large"])
}
RecipeImage.defaultProps = {
  size: "normal"
}

export default RecipeImage
