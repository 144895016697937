import React, {Component} from "react";
import PropTypes from "prop-types";
import {Button} from "@material-ui/core";
import TrackVisibility from "react-visibility-sensor";

const PAGE_SIZE = 10;

export class LoadMore extends React.PureComponent {
    render() {
        const {buttonLabel, buttonStyle = {}/*,  buttonLabelStyle*/} = this.props;
        const label = buttonLabel || "Load next" + (this.props.isVisible ? "..." : "");
        return <Button variant={"contained"} style={buttonStyle} onClick={this.props.onClick}>
            {label}
        </Button>;
    }

    componentDidUpdate() {
        if (this.props.isVisible) this.props.onClick();
    }
}


class LazyLoadComponent extends Component {

    loadMoreResults = () => this.setState({
        count: this.state.count + this.props.pageSize
    });

    constructor(props) {
        super(props);
        this.state = {
            count: props.pageSize
        };
    }

    render() {
        const {count} = this.state;
        const {items, style, className, listClassName, autoLoad, listStyle, buttonLabel, buttonLabelStyle, buttonStyle} = this.props;


        const buttonProps = {
            buttonStyle,
            buttonLabelStyle,
            buttonLabel,
            onClick: this.loadMoreResults
        };

        return (<div className={className} style={{...style}}>
            <div className={listClassName} style={listStyle}>
                {items.slice(0, count)}
            </div>

            <div className={"clearfix"}/>
            {items.length > count ? (
                <div className="text-center" style={{padding: 20}}>
                    {
                        autoLoad ? (
                            <TrackVisibility>
                                {({isVisible}) => <LoadMore isVisible={isVisible} {...buttonProps}/>}
                            </TrackVisibility>
                        ) : (
                            <LoadMore isVisible={false}  {...buttonProps}/>
                        )
                    }
                </div>
            ) : null}
        </div>);
    }
}

LazyLoadComponent.propTypes = {
    pageSize: PropTypes.number,
    items: PropTypes.array,
    style: PropTypes.object,
    listStyle: PropTypes.object,
    className: PropTypes.string,
    listClassName: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonLabelStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    autoLoad: PropTypes.bool
};
LazyLoadComponent.defaultProps = {
    pageSize: PAGE_SIZE,
    items: [],
    style: {},
    buttonLabel: "",
    buttonLabelStyle: {},
    buttonStyle: {},
    listStyle: {},
    className: "",
    listClassName: "",
    autoLoad: true
};

export default LazyLoadComponent;
