import React  from "react"

import RecipeListPage from "../../../layout/page/RecipeListPage"
import withToken from "../../../components/auth/HOC/withToken"
import withUser from "../../../components/auth/HOC/withUser"
import withUserRequiredAuth from "../../../components/auth/HOC/withUserRequiredAuth"
import withTeamRecipeCollection from "../../../components/recipe/arc/teamRecipeCollection"
import { RecipeList } from "../../../components/recipe/components/RecipeList"

const List = withToken(withUser(withUserRequiredAuth(withTeamRecipeCollection(RecipeList))))

const TeamRecipePage = ({match}) => {
  const { teamId } = match.params
  return (
    <RecipeListPage>
      <List teamId={teamId} size={100}/>
    </RecipeListPage>
  )
}

TeamRecipePage.propTypes = {}
TeamRecipePage.defaultProps = {}

export default TeamRecipePage
