import React from 'react'
import { CircularProgress } from '@material-ui/core'

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    display: 'inline-block',
    position: 'relative',
  },
}

function Loader({ style = {}, thickness, loaderStyle, className = 'animated fadeIn text-center', size = 40 }) {
  return (
    <div style={{ ...styles.container, ...style }} className={className}>
      <CircularProgress size={size} thickness={thickness} style={{ ...style.loader, ...loaderStyle }} />
    </div>
  )
}

Loader.propTypes = {}
Loader.defaultProps = {}

export default Loader
