
const defaultState = {
    clientID: null
}


export default function reducer(state = defaultState, action) {

    switch (action.type) {
        case "CLIENT_INIT": {
            return {
                ...state,
                clientID: action.payload.clientID
            }
        }
        case "CLIENT_DESTROY": {
            return {
                ...state,
                clientID: null
            }
        }

        default:
            return state

    }
}
