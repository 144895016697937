
const defaultState = {
  collection: {  },
  available: false
}

export const ACTIONS_MAPPER = {
  UPDATE_ALL: 'CHIMP_EXTENSION_UPDATE_ALL',
  EXTENSION_IS_AVAILABLE: 'CHIMP_EXTENSION_IS_AVAILABLE',
}

const collectionToMap = (collection = [], key= 'id') => {
  return collection.reduce((state, item) => {
    return {
      ...state,
      [item[key]] : item
    }
  }, {})
}


export default function extension(state = defaultState, action) {
  switch (action.type) {
    case ACTIONS_MAPPER.UPDATE_ALL: {
      const {collection} = action.payload
      return {
        ...state,
        collection: collectionToMap(collection)
      }
    }
    case ACTIONS_MAPPER.EXTENSION_IS_AVAILABLE: {
      return {
        ...state,
        available: true
      }
    }
    default:
      return state
  }
}
