import React from "react"
import {
  AppBar,
  withStyles,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Avatar,
  Button
} from "@material-ui/core"
import navigate from "../../singletons/navigate"
import { AccountCircle, NotificationsActive } from "@material-ui/icons"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import connector from "../../components/auth/userAuthConnector"
import "./header.scss"
import { DialogConnect } from "../../components/auth/LdapComponent"
import AbstractAuthComponent from "../../components/auth/components/AbstractAuthComponent"
import { compose } from "redux"
import withUser from "../../components/auth/HOC/withUser"
import withToken from "../../components/auth/HOC/withToken"
import withProfile from "../../components/user/HOC/withPofile"
import LogoUniColor from "../../assets/icons/LogoUniColor"
import withTeamInvitation from "../../components/user/arc/teamInvitation"
import Badge from "@material-ui/core/Badge"


const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: "#FFF",
    color: "#434343",
    //boxShadow: "0 0 0"
  },

  button: {
    color: "#FFF",
    display: "inline-block"
  },
  avatar: {
    margin: 0,
    width: 24,
    height: 24
  },
})


const HeaderMenu = withToken(connect(connector)(
  class extends AbstractAuthComponent {

    toggleAuth = (status) => this.setState({ authVisible: typeof status === "boolean" ? status : !this.state.authVisible })

    toggleSignin = (status) => this.setState({ authVisible: typeof status === "boolean" ? status : !this.state.authVisible })

    constructor() {
      super()
      this.state = {
        authVisible: false
      }
    }

    render() {
      const props = this.props
      return (
        <div>

          <Menu
            key={"menu"}
            id="menu-appbar"
            anchorEl={props.anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={props.open}
            onClose={props.handleClose}
          >
            {this.isLogged() ?
              [(
                <MenuItem key={"profile"} onClick={() => {
                  navigate("/settings/profile")
                  props.handleClose()
                }}>Profile</MenuItem>
              ), (
                <MenuItem key={"teams"} onClick={() => {
                  navigate("/settings/teams")
                  props.handleClose()
                }}>Teams</MenuItem>
              ), (
                <MenuItem key={"logout"} onClick={() => {
                  this.disconnect()
                  props.handleClose()
                  navigate("/")
                }}>Logout</MenuItem>
              )]
              : [
                <MenuItem key='sign-in' onClick={() => {
                  props.handleClose()
                  this.toggleAuth(true)
                }}>Sign in</MenuItem>,
                <Divider key='divider'/>,
                <MenuItem key='sign-up' onClick={() => {
                  props.handleClose()
                  navigate("/sign-up")
                }}>Sign up</MenuItem>
              ]
            }
          </Menu>
          <DialogConnect open={this.state.authVisible} close={() => this.toggleAuth(false)}/>
        </div>
      )
    }
  }
))


const withUserId = (Wrapped) => (props) => <Wrapped {...props} id={props.userId}/>


const Notifications = withUser(withTeamInvitation(({isLogged, loaded,  model }) => {
  if (!isLogged) return null
  const hasNotifications = loaded && Array.isArray(model) && model.length > 0
  const count =  hasNotifications ? model.length : 0
  return <IconButton style={{marginRight: 20}} onClick={() => navigate('/settings/teams')}>
    <Badge badgeContent={count} color="secondary">
      <NotificationsActive />
    </Badge>
  </IconButton>
}))

export class HeaderComponent extends React.Component {

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  // handleSideBar = () => {
  //   this.props.toggler()
  // }

  constructor() {
    super()
    this.state = {
      auth: true,
      anchorEl: null,
      authVisible: false
    }
  }

  render() {
    const { classes, profile, isLogged } = this.props
    const { auth, anchorEl } = this.state
    const open = Boolean(anchorEl)
    return (
      <AppBar elevation={0}  position={"fixed"} className={`${classes.appBar} header`}>
        <Toolbar>

          <LogoUniColor
            prefix="header-svg-logo"
            color={'#434343'} style={{height: 24, width: 24}} className={"header-logo"} />

          <Typography variant="h6" color="inherit" className={classes.flex}
                      style={{ fontFamily: "Manrope", fontWeight: 700, cursor: "pointer", textTransform: "none" }}
                      onClick={() => navigate("/")}>
            Chimp
          </Typography>
          {isLogged && (<Button
            size="small"
            disableElevation
            style={{ marginRight: 20, backgroundColor: '#FC0' }}
            onClick={() => navigate("/recipe/add")}
            variant={"contained"}>
            New Recipe
          </Button>)}

          <Notifications />

          {auth && (
            <div>
              {isLogged ? profile.first_name : "Sign in"}
              <IconButton
                aria-owns={open ? "menu-appbar" : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >

                {isLogged ? (
                  <Avatar alt={profile.first_name} className={classes.avatar} src={profile.avatar}/>
                ) : (
                  <AccountCircle/>
                )}


              </IconButton>
              <HeaderMenu anchorEl={anchorEl} open={open} handleClose={this.handleClose}/>
            </div>
          )}
        </Toolbar>


      </AppBar>
    )
  }
}

HeaderComponent.propTypes = {
  classes: PropTypes.object.isRequired
}


export default compose(withStyles(styles), withUser, withToken, withUserId, withProfile)(HeaderComponent)
