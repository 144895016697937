import config from "../../../config/config"
import store from "../../../config/store"
import { addToast } from "re-toaster/lib/actions/toasterActions"

function handleErrors(response) {
  if (!response.ok) {
    try {
      return response.json().then(r => Promise.reject(r))
    } catch(e) {
      return Promise.reject(response)
    }
  }
  return response
}

export function inviteToTeam({ team = "", email = "", token }) {
  if (!token) return Promise.reject({ message: "Not authorized" })
  return fetch(config.team.invite, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    body: JSON.stringify({
      team,
      email
    })

  }).then(handleErrors)
    .then(r => r.json())
}

export function joinTeam({ team = "", password = "", token }) {
  if (!token) return Promise.reject({ message: "Not authorized" })
  return fetch(config.team.join, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    body: JSON.stringify({
      team,
      password
    })

  }).then(handleErrors).then(r => r.json())
}

export function leaveTeam({ id = "", token }) {
  if (!token) return Promise.reject({ message: "Not authorized" })
  return fetch(config.team.leave + "/" + id, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    }

  }).then(handleErrors).then(r => r.json())
}

export const popError = (message) => {
  store.dispatch(addToast({ type: "warning", icon: "fa fa-warning", message: message }))
}
export const popSuccess = (message) => {
  store.dispatch(addToast({ type: "success", icon: "fa fa-check", message: message }))
}