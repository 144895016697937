import React from "react"

import RecipeModelContainer from "../container/RecipeModelContainer"



function withRecipe(Wrapped) {
  function ComponentLoader(props) {
    const recipe = {
      loaded: props.loaded,
      error: props.error,
      isNew: props.isNew,
      fetch: props.fetch,
      recipe: !props.error && props.loaded ? props.model : {} // multiple products ?
    }
    return <Wrapped {...props.originalProps} {...recipe} />
  }

  return (props) => {
    return (<RecipeModelContainer originalProps={props} {...props} component={ComponentLoader} />)
  }
}


withRecipe.propTypes = {}
withRecipe.defaultProps = {}

export default withRecipe