import React, { useState } from "react"
import Page from "../layout/page/Page"
import Panel from "../layout/panel/Panel"
import withUser from "../components/auth/HOC/withUser"
import { Jumbo } from "../components/home/Welcome"
import GrantComponent from "../components/auth/GrantComponent"
import useFormState from "../components/hooks/useFormState"
import FieldComponent from "../layout/inputs/FieldComponent"
import { Button, Divider } from "@material-ui/core"
import navigate from "../singletons/navigate"
import FieldWrapper from "../layout/inputs/FieldWrapper"
import { compose } from "redux"
import withToken from "../components/auth/HOC/withToken"
import Loader from "../layout/loader/Loader"
import CalloutComponent from "../layout/error/CalloutComponent"
import { joinTeam, popError, popSuccess } from "../components/team/actions/teamActions"


const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 200 }}/>
    </Jumbo>
  )
}



const SettingsTeamJoinPage = ({ token }) => {
  const [state, setState] = useState({ error: null, pending: false, success: false })
  const [form, onChange] = useFormState({ teamName: "", teamPassword: "" })

  const handleJoin = () => {
    const team = form.teamName.trim()
    const password = form.teamPassword.trim()


    if (state.pending || state.success) return

    if (!team || !password) {
      popError("missing team name or password")
      return
    }

    setState({...state, error: null, pending: true })

    joinTeam({ team, password, token }).then(r => {
      setState({ error: null, pending: false, success: true })
      if (r.success === false) return Promise.reject(r)
      popSuccess(r.message)
      setTimeout(() => navigate('/settings/teams'), 200)

    }).catch(r => {
      setState({...state, error: r.message || r, pending: false })
      popError(r.message || r)
    })
  }

  return <Page header={<Heading/>} className="full-width">
    <div className="container">
      <GrantComponent style={{ maxWidth: "400px", margin: "-200px auto 0 auto" }}>
        <Panel style={{ maxWidth: "400px", margin: "-200px auto 0 auto" }} bodyStyle={{ padding: 0 }}>
          <div style={{ padding: "20px 40px" }}>
            <h4>Joining a team</h4>
          </div>
          <Divider/>
          <div style={{ position: "relative" }}>
            <div style={{ position: "absolute", width: "100%", top: 50 }}>
              {state.pending && <Loader/>}
            </div>
          </div>

          {!!state.error && (
            <div className="animated fadeIn">
              <CalloutComponent error={state.error} title={state.error}/>
            </div>)}

          <div style={{ padding: "20px 40px" }}>
            <FieldWrapper>
              <FieldComponent
                disabled={state.success || state.pending}
                autocomplete={false}
                disableUnderline={false}
                value={form.teamName}
                onChange={onChange}
                variant="outlined"
                onEnterKey={handleJoin}
                type="text" name="teamName" placeholder="Team name"/>
            </FieldWrapper>
            <FieldWrapper>
              <FieldComponent
                disabled={state.success || state.pending}
                autocomplete={false}
                disableUnderline={false}
                value={form.teamPassword}
                onChange={onChange}
                variant="outlined"
                onEnterKey={handleJoin}
                type="password" name="teamPassword" placeholder="Team password"/>
            </FieldWrapper>
            <FieldWrapper>
              <Button
                color="primary"
                onClick={handleJoin}
                style={{ display: "block", width: "100%" }}>Join Team</Button>
            </FieldWrapper>
          </div>
        </Panel>
      </GrantComponent>
    </div>
  </Page>
}

SettingsTeamJoinPage.propTypes = {}
SettingsTeamJoinPage.defaultProps = {}

export default compose(withUser, withToken)(SettingsTeamJoinPage)
