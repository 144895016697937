import { ACTIONS_MAPPER } from "../reducers/extension"

export function updateAll({ collection }) {
  return (dispatch) => {
      dispatch({
        type: ACTIONS_MAPPER.UPDATE_ALL,
        payload:{
          collection
        }
      })
  }
}
export function extensionIsAvailable() {
  return (dispatch) => {
      dispatch({
        type: ACTIONS_MAPPER.EXTENSION_IS_AVAILABLE
      })
  }
}