import { doLogin, doLogout } from "../core/authTools"
import { destroySession, storeSession } from "../core/configLess"


export function logout(params) {
  return function(dispatch) {
    const {token} = params
    doLogout({token})
    dispatch({ type: "AUTH_LOGOUT", payload: params })
  }
}


export function authDirectToken(params) {
  return function(dispatch) {
    dispatch({ type: "AUTH_LOGIN", payload: params })
    doLogin(params).then((response) => {

      const payload = {
        // auth_token: token,
        token: response.data.token.token, //'847c399f-d941-4031-aa23-288d5703cd0c',//
        profile: { ...response.data.user },
        expires: response.data.token.expires,
        //roles: token.GRS,
        isAdmin: response.data.user.isAdmin
        //isGranted: token.GRS.includes("PHENIX_WS_USER")
      }
      dispatch({ type: "AUTH_LOGIN_SUCCESSFUL", payload })
      storeSession(payload)
    }).catch(error => {
      console.log(error)
      dispatch({ type: "AUTH_LOGIN_REJECTED", payload: { error } })
    })
  }
}

export function destroyCurrentSession({ token }) {
  return function(dispatch) {
    doLogout({ token })
      .then(() => {
        destroySession()
        dispatch({ type: "AUTH_DESTROY_SESSION", payload: {} })
      })
      .catch(e => {
        console.log(e)
        destroySession()
        dispatch({ type: "AUTH_DESTROY_SESSION", payload: {} })
      })

  }
}