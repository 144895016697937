import { mixerConnector } from "react-arc"
import connect from "react-redux/es/connect/connect"
import FormModelWithAuthContainer from "../../containers/FormModelWithAuthContainer"
import { addToast } from "re-toaster"
import navigate from "../../../../singletons/navigate"
import React from "react"
import FieldComponent from "../../../../layout/inputs/FieldComponent"
import CalloutComponent from "../../../../layout/error/CalloutComponent"
import Loader from "../../../../layout/loader/Loader"
import { Button, Divider} from "@material-ui/core"
import signupConfig from "../../config/signupConfig"
import FieldWrapper from "../../../../layout/inputs/FieldWrapper"

class SignUp extends FormModelWithAuthContainer {

  static defaultProps = {
    ARCConfig: signupConfig
  }

  constructor(props) {
    super(props)
    this.state = {
      mode: "info",
    }
  }

  onSave(created) {
    if (created) {
      this.props.dispatch(addToast({ type: "success", icon: "fa fa-check", message: "You are successully registered" }))
      navigate('/sign-in?status=awaiting-confirmation')
    }
  }


  render() {
    const error = this.getError()
    const loaded = this.isLoaded()

    if (error) return <CalloutComponent error={error}/>
    if (!loaded) return <Loader/>

    const user = this.getModel()

    return (
      <div className="animated fadeIn">
        <div style={{padding: '20px 40px'}}>
          <h4>Create Your Account</h4>

        </div>
        <Divider />



        <div style={{padding: '20px 40px'}}>
          <FieldWrapper>
            <FieldComponent variant={'outlined'} name={"username"} placeholder={"Username"} onChange={this.changeProp} value={user.username || ""}/>
          </FieldWrapper>
          <FieldWrapper>
            <FieldComponent variant={'outlined'} name={"email"} placeholder={"Email"}  onChange={this.changeProp} value={user.email || ""}/>
          </FieldWrapper>

          <FieldWrapper>
            <FieldComponent variant={'outlined'} type={"password"} placeholder={"Password"}  name={"password"} onChange={this.changeProp}
                            value={user.password || ""}/>
          </FieldWrapper>

          <FieldWrapper>
            <FieldComponent variant={'outlined'} type={"password"} placeholder={"Confirm Password"}  name={"passwordConfirm"} onChange={this.changeProp}
                            value={user.passwordConfirm || ""}/>
          </FieldWrapper>

          <FieldWrapper>
            <Button variant={"contained"} color={"primary"} style={{ width: '100%' }}
                    onClick={() => this.save()}>Start</Button>

          </FieldWrapper>

        </div>




      </div>
    )
  }
}

export default mixerConnector(connect, signupConfig, (store) => ({
  token: store.auth.user.token
}))(SignUp)