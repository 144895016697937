import React, { PureComponent } from "react"
import {Route} from  'react-router'
import PageRecipe from "../../pages/recipe/RecipePage"
import TeamRecipePage from "../../pages/recipe/team/TeamRecipePage"
import TypeRecipePage from "../../pages/recipe/types/TypeRecipePage"
import RecipesPage from "../../pages/recipe/PageRecipes"

class RouterRecipe extends PureComponent {
  render() {
    return (
      <React.Fragment>
        <Route exact path="/type/:type?" name="Recipes" component={TypeRecipePage}/>
        <Route exact path="/team/:teamId" name="Recipes" component={TeamRecipePage}/>
        <Route exact path="/recipe/:id?" name="Recipes" component={PageRecipe}/>
        <Route exact path="/recipes" name="Recipes" component={RecipesPage}/>
      </React.Fragment>
    )
  }
}

RouterRecipe.propTypes = {}
RouterRecipe.defaultProps = {}

export default RouterRecipe
