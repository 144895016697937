import React from "react"
import navigate from "../singletons/navigate"
import { Home, GroupTwoTone } from "@material-ui/icons"
import withUser from "../components/auth/HOC/withUser"
import withUserTeams from "../components/user/arc/UserTeams"
import withARCLoader from "../components/auth/HOC/withARCLoader"


function FatButton(props) {
  const { toggler } = props
  return (
    <button className="fat-button" onClick={() => {
      toggler(false)
      navigate(props.to)
    }}>
      <div className="icon">
        {props.icon}
      </div>
      <div className="title">{props.title}</div>
    </button>)
}

const styles = {
  icon: {
    verticalAlign: "middle"
  }
}

const UserTeamLinks = withUser(withUserTeams(withARCLoader(({ model = [] , ...props}) => {
  const teams = Array.isArray(model) ? model : []
  return <div>
    {teams.map(team => {
      return (
        <FatButton {...props} key={team.id} title={team.name} to={"/team/" + team.id}
                   icon={<GroupTwoTone style={styles.icon}/>}/>
      )
    })}
  </div>
})))

export function SidebarMenu(props) {
  return (
    <div>
      <FatButton {...props} title="Recipes" to="/recipes" icon={<Home style={styles.icon}/>}/>
      <UserTeamLinks {...props}/>
    </div>
  )

}

export default SidebarMenu
