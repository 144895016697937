import React from "react"
import useToggle from "../../../hooks/useToggle"

function SideBarSection({ children, title, style, containerStyle, togglable= true }) {

  const [open, toggle] = useToggle(true)
  const iconClassName = "animated fadeIn fa"
  return (
    <div className="sidebar-section" style={style}>
      {!!title && (<div className="sidebar-section-title" onClick={() => togglable && toggle()}>
        {title} {togglable && (<span className={`${iconClassName} fa-caret-${open ? "down" : "right"}`}/>)}
      </div>)}
      {open && (
        <div className="sidebar-section-content animated fadeIn" style={containerStyle}>
          {children}
        </div>
      )}

    </div>

  )
}

SideBarSection.propTypes = {}
SideBarSection.defaultProps = {}

export default SideBarSection
