import React  from "react"
import PropTypes from "prop-types"
import config from "../../../config/config"
import { ImagePlaceHolder } from "./RecipeImage"


const styles = {
  image: {
    //minHeight: 400,
    //border: "1px solid #DEDEDE",
    //margin: "10px 0",
    //backgroundSize: "100% auto",
    //backgroundPosition: "50% 50%",
    //backgroundRepeat: "no-repeat",
    //borderRadius: 3,
    //lineHeight: "400px",
    //backgroundColor: "#FFF",
    textAlign: "center"
  },
  title: {

  }
}


const RecipeImageThumb = ({ recipe, size, style }) => {
  const hasMedia = !!recipe.media
  const source = config.images + "/images/" + (size === "large" ? "original/" : "") + recipe.media

  return (<div className="media-container" style={style}>
    <div className={'media-overlay' + (hasMedia ? '' : ' no-media')}>
      <div className="media-title animated fadeInUp">
        {recipe.name}
      </div>
    </div>
    {
      !hasMedia ? (<ImagePlaceHolder color={'#656888'} />) : (
        <div className="media-wrapper" style={{
          ...styles.image,
          backgroundImage: "url(" + source + ")"
        }}/>
      )
    }
  </div>)
}

RecipeImageThumb.propTypes = {
  recipe: PropTypes.object,
  size: PropTypes.oneOf(["normal", "large"])
}
RecipeImageThumb.defaultProps = {
  size: "normal"
}

export default RecipeImageThumb
