import React, { PureComponent } from "react"
import Page from "../layout/page/Page"
import Panel from "../layout/panel/Panel"
import withUser from "../components/auth/HOC/withUser"
import { Jumbo } from "../components/home/Welcome"
import UserProfile from "../components/user/containers/ProfileEdit"
import PageTitle from "../layout/page/PageTitle"
import withUserCheck from "../components/auth/HOC/withUserCheck"


const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 200 }}/>
    </Jumbo>
  )
}

class SettingsProfilePage extends PureComponent {
  render() {
    return (
      <Page header={<Heading/>} className="full-width">
        <div className="container">
          <div style={{ margin: "-250px auto 0 auto" }}>
            <PageTitle
              title={"Your profile"}
              subtitle={"Update your information"}
            />
            <Panel  bodyStyle={{ padding: 30 }}>
              <div style={{  margin: "auto" }}>
                <UserProfile id={this.props.userId}/>
              </div>
            </Panel>
          </div>
        </div>
      </Page>
    )
  }
}

SettingsProfilePage.propTypes = {}
SettingsProfilePage.defaultProps = {}

export default withUser(withUserCheck(SettingsProfilePage))
