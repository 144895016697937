import React from "react"
const Username = ({ user }) => {
  return <strong style={{ marginRight: 10 }}>{user.username || user.recipient_email.split("@").shift()}</strong>
}


Username.propTypes = {}
Username.defaultProps = {}

export default Username
