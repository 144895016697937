import React from 'react';
import ReactDOM from 'react-dom';
//import App from './App';
import registerServiceWorker from './registerServiceWorker';

/** GLOBALS **/
//import 'typeface-roboto'
import setup from "./config/setup";




setup().then(() => {
    const App = require('./App').default
    const root = document.getElementById('root')
    ReactDOM.render(<App/>, root);
    if (module.hot) {
        module.hot.accept('./App', () => {
            const NextApp = require('./App').default;
            ReactDOM.render(<NextApp/>, root);
        })
    }
})
registerServiceWorker();
