import React from "react"
import useToggle from "../../hooks/useToggle"
import { Column, Row } from "../../../layout/grid"
import Button from "@material-ui/core/Button"
import InviteDialog from "../../team/invite/InviteDialog"
import InviteToTeam from "../../team/invite/InviteToTeam"
import { EmailOutlined } from "@material-ui/icons"
import UserAvatar from "./UserAvatar"
import Username from "./Username"
import DropDownMenu from "../../../layout/dropdown/DropDownMenu"
import withUser from "../../auth/HOC/withUser"
import { useARC } from "react-arc/lib/hooks/useARC"
import { configUserRole } from "../arc/UserRole"
import withToken from "../../auth/HOC/withToken"
import store from "../../../config/store"
import { addToast } from "re-toaster"
import Loader from "../../../layout/loader/Loader"


const styles = {
  row: {
    height: 50,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  profile: {
    flexDirection: "row", display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  }
}


const AVAILABLE_ROLES = [
  {
    level: 0,
    id: "member",
    value: "member",
    primaryText: "Member"
  },
  {
    level: 1,
    id: "manager",
    value: "manager",
    primaryText: "Manager"
  },
  {
    level: 2,
    id: "admin",
    value: "admin",
    primaryText: "Admin"
  }
]

const getRoleLevel = (role) => {
  return role === "owner" ? 10 : AVAILABLE_ROLES.find(r => r.value === role).level
}

const getRole = (team, username) => {
  return team.owner.username === username ? "owner" : team.members.find(member => {
    return member.username === username
  }).role
}

const Roles = ({ roles, role, onChange }) => {
  return <DropDownMenu
    //labelStyle={this.props.labelStyle}
    value={role}
    options={roles}
    onChange={callback => onChange(callback.value)}
  />
}


const UserRow = withUser(withToken(({ member, team, user, token, refresh }) => {

  const {  loading, arc } = useARC({
    ARCConfig: configUserRole,
    props: {
      token,
      team: team.id,
    },
    params: {
      team: team.id,
      token
    }
  })

  const userRole = getRole(team, user.username)
  const roleLevel = getRoleLevel(userRole)
  const roles = AVAILABLE_ROLES.filter(p => p.level <= roleLevel)

  const handleChangeRole = (role) => {
    arc
      .update({
        body: {
          role,
          username: member.username
        }
      })
      .then(() => {
        refresh()
        store.dispatch(
          addToast({
            type: "success",
            icon: "fa fa-check",
            message: `Username is now granted with the role ${role}`
          })
        )
      })
      .catch(() => {
        store.dispatch(
          addToast({
            type: "error",
            icon: "fa fa-warning",
            message: "Some Error happened !"
          })
        )
      })
  }


  return (<Row style={styles.row} key={member.username} className="animated fadeIn px-list-item">
    <Column size={3}>
      <div style={styles.profile}>
        <UserAvatar user={member}/>
        <Username user={member}/>
      </div>
    </Column>
    <Column size={2}>{`${member.first_name} ${member.last_name}`}</Column>
    <Column size={4}>{member.email}</Column>
    <Column size={2} className="text-right">
      {user.username === member.username || roleLevel === 0 ? (
        <Button disabled style={{marginRight: 20}}>{member.role}</Button>
      ) : (
        <Roles roles={roles} role={member.role} onChange={handleChangeRole}/>
      )}

    </Column>
    <Column size={1}>
      {loading && <Loader size={16} />}
    </Column>
  </Row>)
}))

const MemberList = (({ team, refresh, leaveTeam }) => {

  const [open, toggle] = useToggle(false)

  const onInviteSuccess = () => {
    toggle(false)
    refresh()
  }
  const handleLeave = () => leaveTeam(team)


  return (<div className="px-list">
    <h5>Owner</h5>
    <div className="px-list" style={{ margin: "10px 10px 20px 10px" }}>
      <Row style={styles.row} className="animated fadeIn px-list-item">
        <Column size={3}>
          <div style={styles.profile}>
            <UserAvatar user={team.owner}/>
            <Username user={team.owner}/>
          </div>
        </Column>
        <Column size={2}>{`${team.owner.first_name} ${team.owner.last_name}`}</Column>
        <Column size={4}>{team.owner.email}</Column>
        <Column size={2}>{team.owner.role}</Column>
      </Row>
    </div>
    <h5>Members</h5>
    <div className="px-list" style={{ margin: "10px 10px 20px 10px" }}>
      {team.members.map(member => (
        <UserRow key={member.username} member={member} team={team} refresh={refresh}/>
      ))}
    </div>
    {team.pending.length > 0 && (
      <React.Fragment>
        <h5>Invited</h5>
        <div className="px-list" style={{ margin: "10px 10px 20px 10px" }}>
          {team.pending.map(member => (
            <Row style={styles.row} key={member.recipient_email} className="no-gutters animated fadeIn px-list-item">
              <Column size={3}>
                <div style={styles.profile}>
                  <UserAvatar user={member}/>
                  <Username user={member}/>
                </div>
              </Column>
              <Column
                size={2}>{member.username ? `${member.first_name} ${member.last_name}` : "Waiting for registration"}</Column>
              <Column size={4}>{member.recipient_email}</Column>
              <Column size={2}></Column>
            </Row>
          ))}
        </div>
      </React.Fragment>
    )}
    <Row style={{ ...styles.row, marginTop: 20 }} className="no-gutters animated fadeIn">
      <Column size={6}>
        {!team.isOwner && (<Button onClick={handleLeave}>Leave Team</Button>)}
      </Column>
      <Column size={6}>
        {team.canInvite && (
          <React.Fragment>
            <InviteDialog open={open} onClose={() => toggle(false)}>
              <InviteToTeam teamName={team.name} teamId={team.id} onSuccess={onInviteSuccess}/>
            </InviteDialog>
            <Button onClick={() => toggle(true)}> <EmailOutlined style={{ marginRight: 20 }}/> Invite Someone
              to {team.name}</Button>
          </React.Fragment>
        )}
      </Column>
    </Row>
  </div>)
})

MemberList.propTypes = {}
MemberList.defaultProps = {}

export default withUser(MemberList)
