const USER_STORE_KEY = "snowflake/user"

export class EncodedStorage {
  constructor(options) {
    this.key = options.key
  }

  encode(str) {
    if (typeof str !== "string") throw new Error("String expected")
    try {
      const escaped = unescape(encodeURIComponent(str))
      return btoa(escaped)
    } catch (e) {
      console.log("Cant encode string")
      console.log(e)
      return str
    }
  }

  decode(str) {
    try {
      const unescaped = decodeURIComponent(escape(str))
      return atob(unescaped)
    } catch (e) {
      console.log("BAD DATA FORMAT !")
      console.log(e)
      return str
    }

  }

  get() {
    const content = localStorage.getItem(this.key)
    return content ? this.decode(content) : null
  }

  set(value) {
    const content = this.encode(value)
    localStorage.setItem(this.key, content)
  }

  destroy() {
    localStorage.removeItem(USER_STORE_KEY)
  }
}

const storage = new EncodedStorage({ key: USER_STORE_KEY })

/**
 * Storing data for basic auth
 */

export function storeSession(user) {
  //const expires = new Date().getTime() + TIMEOUT_SESSION
  const _user = { ...user }
  storage.set(JSON.stringify(_user))
}

export function destroySession() {
  storage.destroy()
}

/**
 * Alternative Auth user
 * using token
 */

//
// const remainingTime = (user) => {
//   const now = new Date().getTime()
//   const end = user.expires * 1000
//   console.log('remaining ', Math.round((end - now) / 1000) + ' secs')
// }

export function loadSession() {
  const data = storage.get()
  return new Promise((resolve, reject) => {
    if (data) {
      // Previous login found
      const user = JSON.parse(data)
      if (user && (user.expires * 1000) - new Date().getTime() > 0) {
        //remainingTime(user)
        resolve(user)
      } else {
        destroySession()
        reject("session expired")
      }

    } else {
      reject("No session found")
    }
  })
}