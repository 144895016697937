import React, { useEffect, useRef, useState } from "react"
//import PropTypes from "prop-types"
import TeamList from "../TeamList"
import ExtensionRecipeToolbar from "../extension/ExtensionRecipeToolbar"
import Tags from "../tags/Tags"
import SideBarSection from "./SideBarSection"
import AuthorAndDate from "./AuthorAndDate"
import moment from "moment"
import useToggle from "../../../hooks/useToggle"
import { Button } from "@material-ui/core"
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons"
import withExtensionState from "../../../extension/HOC/withExtensionState"

//import RecipeTitle from "../RecipeTitle"


const tagStyles = {
  wrapper: { lineHeight: "30px", verticalAlign: "middle", textAlign: "right" },
  tag: { fontSize: 10, lineHeight: "30px", display: "inline-block", color: "#999", textTransform: "uppercase" }
}
const TagWrapper = ({ children, title }) => {
  return (
    <div style={tagStyles.wrapper}>
          <span className="float-left" style={tagStyles.tag}>
            {title}
          </span>
      {children}
    </div>
  )
}


const styles = {
  collapsible: {
    overflow: 'hidden',
    transition: 'height 400ms ease-out',
    marginBottom: 10
  }
}


const CollapsibleDescription = ({ recipe }) => {
  const heightMinimized = 100
  const [visible, toggle] = useToggle(false)
  const [maxHeight, setMaxHeight] = useState(heightMinimized)
  const Icon = visible ? ArrowDropUp : ArrowDropDown
  const contentRef = useRef()
  const isCollapsible = maxHeight > heightMinimized

  useEffect(() => {
    if(contentRef.current) {
      setMaxHeight(contentRef.current.offsetHeight)
    }
  }, [contentRef, recipe.description])

  if(!recipe.description.trim()) return null
  return (
    <div>
      <div style={{
        ...styles.collapsible,
        ...(isCollapsible ? {height: visible ? maxHeight : heightMinimized, maxHeight: '100%'} : {maxHeight: heightMinimized}),
      }}>
        <div ref={contentRef}>
          <p>{recipe.description}</p>
        </div>
      </div>
      {isCollapsible && (<div>
        <Button onClick={toggle} size="small">{
          visible ? "less" : "more"
        }
          <Icon style={{ color: "#888" }}/>
        </Button>
      </div>)}
    </div>
  )
}

const RecipeInfo = ({ isNew, recipe, found, enabled, userInfo, children, extensionAvailable }) => {

  const fromNow = moment(recipe.date).fromNow()
  return (
    <React.Fragment>


      {(!isNew && extensionAvailable) && (
        <SideBarSection style={{ flex: "none", height: 60 }}>
          <ExtensionRecipeToolbar
            recipe={recipe}
            id={recipe.id}
            installed={found}
            enabled={enabled}
          />
        </SideBarSection>
      )}

      <SideBarSection containerStyle={{ padding: "20px " }}
                      style={{ flex: "none", height: "auto", overflow: "hidden" }}>
        <div style={{ fontWeight: 800, fontSize: 20 }}>{recipe.name}</div>
        <p><a href={recipe.site}>{recipe.site}</a></p>
        <CollapsibleDescription recipe={recipe}/>
      </SideBarSection>

      <SideBarSection containerStyle={{
        height: 200,
        padding: 10, backgroundColor: "#FFF", margin: 10, borderRadius: 10
      }}>
        <div style={{ marginLeft: -10 }}>
          <AuthorAndDate userInfo={userInfo} dateText={"Updated " + fromNow}/>
        </div>
        <TagWrapper title={"Tags"}>
          <Tags recipe={recipe}/>
        </TagWrapper>


        {recipe.teams && recipe.teams.length > 0 && (<TagWrapper title={"Teams"}>
          <TeamList teams={recipe.teams}/>
        </TagWrapper>)}
      </SideBarSection>


      {children}


      {/*{!isNew && (*/}
      {/*  <SideBarSection title={"Extension controls"}>*/}
      {/*    <ExtensionRecipeToolbar*/}
      {/*      id={recipe.id}*/}
      {/*      installed={found}*/}
      {/*      enabled={enabled}*/}
      {/*    />*/}
      {/*  </SideBarSection>*/}
      {/*)}*/}

    </React.Fragment>
  )
}

RecipeInfo.propTypes = {}
RecipeInfo.defaultProps = {}

export default withExtensionState(RecipeInfo)
