import { useState } from 'react'

function useItemPicker(defaultState = null) {
  const [state, setState] = useState({ itemPicked: defaultState })
  const toggle = (item) => setState({
    itemPicked: item
  })
  return [state.itemPicked, toggle]
}

export default useItemPicker