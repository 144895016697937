import { connect } from "react-redux"
import { mixerConnector } from "react-arc"
import { compose } from "redux"
import { ModelWithAuthContainer } from "../../auth/containers/ModelWithAuthContainer"
import withToken from "../../auth/HOC/withToken"
import createHOC from "../../arc/createHOC"
import config from "../../../config/config"


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> teamInvitation
 [ ] ADD MODEL REQUIRED PROPS, in configTeamInvitation
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const TEAM_INVITATION_NAMESPACE = "TEAM_INVITATION"

export const configTeamInvitation = {
  name: "teamInvitation",
  uppercaseName: TEAM_INVITATION_NAMESPACE,
  modelProps: ['token'],
  collectionProps: [],
  paths: {
    item: config.team.invitations,
    accept: config.team.invitations + '/{invitation}'
  },
  headers: {
    Accept: "application/json",
    "X-Auth-Token": "{token}"
  },
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100
}

const types = {}

export class teamInvitationContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configTeamInvitation
  }
  static propTypes = types
}

const connector = mixerConnector(connect, configTeamInvitation)
export const teamInvitationContainer = compose(withToken, connector)(teamInvitationContainerBase)

const withTeamInvitation = createHOC({
  Container: teamInvitationContainer,
  ARCConfig: configTeamInvitation
})
withTeamInvitation.propTypes = types

withTeamInvitation.defaultProps = {}

export default withTeamInvitation