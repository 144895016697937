import React from 'react'
import { filterARCCONFIG } from "./utils"

const ComponentLoader = ({ Wrapped, ARC_config, ...props }) => {
  const defaultModel = JSON.parse(JSON.stringify(ARC_config?.defaultModel || {}))
  const data = {
    loading: props.loading,
    loaded: props.loaded,
    error: props.error,
    model: !props.error && props.loaded ? props.model : defaultModel,
    collection: !props.error && props.loaded ? props.collection : [],
    fetch: props.fetch,
    ...(props.extendedProps||{})
  }
  return <Wrapped {...props.originalProps} {...data} />
}

function createHOC({ Container, ARCConfig }) {
  //console.log(ARCConfig)
  return function HOC(Wrapped) {
    return function ARCComponent(props) {
      return (
        <Container
          {...props}
          originalProps={filterARCCONFIG(props)}
          component={ComponentLoader}
          ARC_config={ARCConfig}
          Wrapped={Wrapped}
        />
      )
    }
  }
}

export default createHOC