import React from "react"

//import PropTypes from "prop-types"

const styles = {
  badge: {}
}

function Tags({ recipe }) {
  return (
    <>
      {recipe.css.trim() && (
        <span style={styles.badge} className="badge badge-css">CSS</span>
      )}
      {recipe.js.trim() && (
        <span style={styles.badge} className="badge badge-js">JS</span>
      )}
      {!!parseInt(recipe.private, 10) && (
        <span style={styles.badge} className="badge badge-private">PRIVATE</span>
      )}
    </>
  )
}

Tags.propTypes = {}
Tags.defaultProps = {}

export default Tags
