import React  from "react"
import PropTypes from "prop-types"
import navigate from "../../../singletons/navigate"

import TeamList from "./TeamList"
import RecipeImageThumb from "./RecipeImageThumb"
import { Avatar, withStyles } from "@material-ui/core"
import moment from "moment"
import withRecipeExtensionState from "../../extension/HOC/withRecipeExtensionState"
import Button from "@material-ui/core/Button"
import { Check } from "@material-ui/icons"

const styles = {
  badge: {}
}


const muiStyles = theme => ({
  avatar: {
    margin: "0 10px 0 0",
    width: 16,
    height: 16,
    color: '#333',
    fontSize: 11,
    lineHeight: '16px'
  }
})


const LabelList = ({ recipe }) => {
  return 1 === 2 && <div className="labels">
    {recipe.css.trim() ? (
      <span style={styles.badge} className="badge badge-css">CSS</span>
    ) : null}
    {recipe.js.trim() ? (
      <span style={{ ...styles.badge }} className="badge badge-js">JS</span>
    ) : null}
    {parseInt(recipe.private) ? (
      <span style={styles.badge} className="badge badge-private">PRIVATE</span>
    ) : null}
  </div>
}

const RecipeDate = ({recipe}) => {
  return (<div className="recipe-date text-muted">@{moment((recipe.updated || recipe.date)).format('LL')}</div>)
}

const RecipeThumb = ({ recipe, classes, className, recipeInstalled}) =>  {
  return (
    <div className={'recipe ' + className} onClick={() => navigate("/recipe/" + recipe.id)}>
      {recipeInstalled && (<Button
        size={"small"}
        className="recipe-installed-tag">
        <Check style={{marginRight: 10}} /> installed
      </Button>)}
      <RecipeImageThumb recipe={recipe}/>
      <div className="recipe-info">
        <TeamList teams={recipe.teams} className="team-list"/>
        <h3 className="ellipsis">

          <Avatar alt={recipe.first_name}
                  className={classes.avatar}
          >{recipe.author_name.substr(0,1)}</Avatar>
          {recipe.author_name}
        </h3>
        <RecipeDate recipe={recipe} />
        <LabelList recipe={recipe}/>
      </div>
    </div>
  )
}

RecipeThumb.propTypes = {
  recipe: PropTypes.object,
  className: PropTypes.string
}
RecipeThumb.defaultProps = {
  className: ""
}

export default withStyles(muiStyles)(withRecipeExtensionState(RecipeThumb))
