import React  from "react"
import Page from "../layout/page/Page"
import Panel from "../layout/panel/Panel"
import withUser from "../components/auth/HOC/withUser"
import { Jumbo } from "../components/home/Welcome"
import UserTeams from "../components/user/containers/UserTeamsEdit"
import PageTitle from "../layout/page/PageTitle"
import withUserCheck from "../components/auth/HOC/withUserCheck"


const Heading = () => {
  return (
    <Jumbo>
      <div style={{ height: 200 }}/>
    </Jumbo>
  )
}

const SettingsTeamsPage = ({userId}) => {
  return (<Page header={<Heading/>} className="full-width">
    <div className="container">
      <div style={{ margin: "-250px auto 0 auto" }}>
        <PageTitle
          title={"Your teams"}
          subtitle={"Manage your teams and collaborators"}
        />
        <Panel bodyStyle={{ padding: 0 }}>
          <UserTeams id={userId}/>
        </Panel>
      </div>
    </div>
  </Page>)
}

SettingsTeamsPage.propTypes = {}
SettingsTeamsPage.defaultProps = {}

export default  withUser(withUserCheck(SettingsTeamsPage))
