import React from "react"
import { connect } from "react-redux"
function withExtensionData(Wrapped) {
  return connect((store) => ({
    extensionData: store.extension.collection
  }))(({extensionData, ...props}) => {
    const data = Object.values(extensionData)
    return <Wrapped  {...props} extensionData={data}/>
  })
}

export default withExtensionData
