import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'


function Confirm(props) {
  const { onClose, onAccept, open, title, labelAccept, labelReject, content, ...other } = props
  const handleCancel = () => onClose()
  const handleOk = () => {
    if (onAccept) onAccept()
    onClose()
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      maxWidth="sm" fullWidth>
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      {!!content && (<DialogContent dividers>
        {content}
      </DialogContent>)}
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {labelReject}
        </Button>
        <Button onClick={handleOk} color="primary">
          {labelAccept}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Confirm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.any,
  labelAccept: PropTypes.string,
  labelReject: PropTypes.string
}

Confirm.defaultProps = {
  title: 'Confirm this action',
  labelAccept: 'Ok',
  labelReject: 'Cancel'
}

export default Confirm

