import { useState } from 'react'

function useFormState(defaultState = {}) {
  const [state, setState] = useState(defaultState)
  const setFormItem = (prop, value) => {
    setState({
      ...state,
      [prop]: value
    })
  }
  const setFullForm = (form) => {
    setState({
      ...state,
      ...form
    })
  }
  return [state, setFormItem, setFullForm]
}

export default useFormState