import PropTypes from "prop-types"
import { connect } from "react-redux"
import { mixerConnector } from "react-arc"
import { compose } from "redux"
import { ModelWithAuthContainer } from "../../auth/containers/ModelWithAuthContainer"
import withToken from "../../auth/HOC/withToken"
import config from "../../../config/config"
import createHOC from "../../arc/createHOC"


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> teamRecipeCollection
 [ ] ADD MODEL REQUIRED PROPS, in configTeamRecipeCollection
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const TEAM_RECIPE_COLLECTION_NAMESPACE = "TEAM_RECIPE_COLLECTION"

export const configTeamRecipeCollection = {
  name: "teamRecipeCollection",
  uppercaseName: TEAM_RECIPE_COLLECTION_NAMESPACE,
  modelProps: ['teamId'],
  collectionProps: [],
  paths: {
    item: config.recipe.team
  },
  headers: {
    Accept: "application/json",
    "X-Auth-Token": "{token}"
  },
  fetchOnce: true,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100
}

const types = {
  teamId: PropTypes.string.isRequired
}

export class teamRecipeCollectionContainerBase extends ModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: configTeamRecipeCollection
  }
  static propTypes = types
}

const connector = mixerConnector(connect, configTeamRecipeCollection)
export const teamRecipeCollectionContainer = compose(withToken, connector)(teamRecipeCollectionContainerBase)

const withTeamRecipeCollection = createHOC({
  Container: teamRecipeCollectionContainer,
  ARCConfig: configTeamRecipeCollection
})
withTeamRecipeCollection.propTypes = types

withTeamRecipeCollection.defaultProps = {}

export default withTeamRecipeCollection