import React  from "react"
//import PropTypes from "prop-types"

const TeamList = ({teams= [], className=""}) => (<div className={className}>
  {teams.map(team => (
    <span key={team.id} className="badge badge-team">{team.name}</span>
  ))}
</div>)

TeamList.propTypes = {}
TeamList.defaultProps = {}

export default TeamList
