import React from "react"
//import PropTypes from "prop-types"
import RecipeInfo from "./RecipeInfo"
import './sidebar.scss'


function RecipeSideBar({isNew, userInfo, found, enabled, recipe, children}) {
  return (
    <div className="recipe-sidebar">
      <RecipeInfo isNew={isNew} userInfo={userInfo} found={found} enabled={enabled} recipe={recipe}>
        {children}
      </RecipeInfo>
    </div>

  )
}

RecipeSideBar.propTypes = {}
RecipeSideBar.defaultProps = {}

export default RecipeSideBar
