import React  from "react"
import UserContainer from "../containers/UserContainer"


function withUser(Wrapped) {
  const ComponentLoader = ({ isLogged, user,originalProps, userChecked }) => {
    const data = {
      isLogged,
      user,
      userChecked,
      userId: isLogged ? user.id : undefined
    }
    return <Wrapped {...data} {...originalProps} />
  }

  return (props) => <UserContainer originalProps={props} {...props} component={ComponentLoader}/>
}


withUser.propTypes = {}
withUser.defaultProps = {}

export default withUser