import config from "../../../config/config"
import axios from "axios"
//import jwtDecode from "jwt-decode"

//const TIMEOUT_SESSION = 60 * 60 * 1000 * 5 // 5 min


/**
 * Direct Auth user
 * using token
 */

export function doLogin(params) {

  const body = {
    "username": params.username,
    "password": params.password
  }
  return axios({
    url: config.login,
    method: "post",
    data: body
  })

}

export function doLogout({ token }) {

  if (!token) return Promise.reject("Missing token")
  return axios({
    url: config.logout,
    method: "get",
    headers: {
      "X-Auth-Token": token
    }
  })

}
