import PropTypes from "prop-types"
import { connect } from "react-redux"
import { mixerConnector } from "react-arc"
import { compose } from "redux"
import withToken from "../../auth/HOC/withToken"
import createHOC from "../../arc/createHOC"
import config from "../../../config/config"
import ModelContainer from "../../arc/containers/ModelContainer"


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> recipesCollection
 [ ] ADD MODEL REQUIRED PROPS, in configRecipesCollection
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const RECIPES_COLLECTION_NAMESPACE = "RECIPES_COLLECTION"

export const configRecipesCollection = {
  name: "recipesCollection",
  uppercaseName: RECIPES_COLLECTION_NAMESPACE,
  modelProps: ["size", "token"],
  collectionProps: [],
  paths: {
    item: config.recipe.recipe
  },
  headers: {
    Accept: "application/json",
    "X-Auth-Token": "{token}"
  },
  fetchOnce: false,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100,
  defaultModel: []
}

const types = {
  token: PropTypes.string,
  size: PropTypes.number
}

export class RecipesCollectionContainerBase extends ModelContainer {
  static defaultProps = {
    ARCConfig: configRecipesCollection,
    size: 100,
    token: ''
  }
  static propTypes = types
}

const connector = mixerConnector(connect, configRecipesCollection)
export const RecipesCollectionContainer = compose(withToken, connector)(RecipesCollectionContainerBase)

const withRecipesCollection = createHOC({
  Container: RecipesCollectionContainer,
  ARCConfig: configRecipesCollection
})
withRecipesCollection.propTypes = types

withRecipesCollection.defaultProps = {}

export default withRecipesCollection