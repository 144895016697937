import React  from "react"
import RecipeList from "../../components/recipe/components/RecipeList"
import RecipeListPage from "../../layout/page/RecipeListPage"



function RecipesPage() {
  return (
    <RecipeListPage>
      <RecipeList size={100}/>
    </RecipeListPage>

  )
}

RecipesPage.propTypes = {}
RecipesPage.defaultProps = {}

export default RecipesPage
