/* not GENERATED WITH CASTER */

const defaultuser = {
  username: "",
  password: "",
  credentialValid: false,
  pending: false,
  profile: null,
  isAdmin: false,
  sessionCheck: false,
  token: "",
  expires: null
}
const defaultState = {
  user: { ...defaultuser }
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "AUTH_LOGOUT": {
      return {
        ...state,
        user: { ...defaultuser }
      }
    }

    case "AUTH_LOGIN": {
      return {
        ...state,
        user: {
          ...state.user,
          username: action.payload.username,
          password: action.payload.password,
          pending: true,
          credentialValid: false
        }
      }
    }
    case "AUTH_LOGIN_SUCCESSFUL": {
      return {
        ...state,
        user: {
          ...state.user,
          pending: false,
          credentialValid: true,
          isAdmin: action.payload.isAdmin,
          profile: action.payload.profile,
          token: action.payload.token,
          expires: action.payload.expires
        }
      }
    }
    case "AUTH_LOGIN_REJECTED": {
      return {
        ...state,
        user: {
          ...state.user,
          pending: false,
          credentialValid: false,
          error: action.payload.error
        }
      }
    }

    case "AUTH_DESTROY_SESSION": {
      return {
        ...state,
        user: { ...defaultuser }
      }
    }
    case "AUTH_RELOAD_SESSION": {
      return {
        ...state,
        user: {
          ...state.user,
          pending: true,
          credentialValid: false,
          sessionCheck: true
        }
      }
    }
    case "AUTH_RELOAD_SESSION_SUCCESSFUL": {
      return {
        ...state,
        user: {
          ...state.user,
          pending: false,
          credentialValid: true,
          isAdmin: action.payload.isAdmin,
          profile: action.payload.profile,
          token: action.payload.token,
          expires: action.payload.expires
        }
      }
    }
    case "AUTH_RELOAD_SESSION_REJECTED": {
      return {
        ...state,
        user: {
          ...state.user,
          pending: false,
          credentialValid: false,
          error: action.payload.error
        }
      }
    }

    default:
      return state
  }
}
