import React from "react";
import PropTypes from "prop-types";
import {MenuItem, Menu, Button, IconButton, ListItemIcon} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";


class DropDownMenu extends React.Component {

    open = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClick = (option) => {
        if (this.props.onChange) this.props.onChange(option);
        this.setState({anchorEl: null});
    };
    handleClose = () => {
        this.setState({anchorEl: null});
    };

    constructor(props) {
        super(props);
        this.button = undefined;
        this.state = {
            anchorEl: null
        };
    }

    render() {

        const {value, options, labelStyle, icon, PaperProps} = this.props;
        const {anchorEl} = this.state;

        const text = options.find(o => o.value === value);

        return ([
                icon ? (
                        <IconButton
                            key={"button"}
                            onClick={this.open}>
                            {icon}
                        </IconButton>
                    ) :
                    (<Button
                        key={"button"}
                        style={labelStyle}
                        onClick={this.open}
                    >
                        {text ? text.primaryText : value} <ArrowDropDown/>
                    </Button>),
                <Menu
                    key={"menu"}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    PaperProps={PaperProps}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === value}
                            onClick={option.onClick || (() => this.handleClick(option))}
                        >
                            {option.leftIcon ? (
                                <ListItemIcon>
                                    {option.leftIcon}
                                </ListItemIcon>
                            ) : null}

                            {option.primaryText}

                            {option.rightIcon ? (
                                <ListItemIcon>
                                    {option.rightIcon}
                                </ListItemIcon>
                            ) : null}
                        </MenuItem>
                    ))}
                </Menu>
            ]


        );
    }
}

DropDownMenu.propTypes = {
    icon: PropTypes.any,
    labelStyle: PropTypes.object,
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    PaperProps: PropTypes.object
};
DropDownMenu.defaultProps = {
    labelStyle: {},
    options: []
};

export default DropDownMenu;