import config from "../../../config/config"

export const registrationConfirmationConfig = {
  name: "registrationConfirmation",
  //used in the reducers
  uppercaseName: "REGISTRATION_CONFIRMATION",
  // useful to map objects in collection
  modelProps: ['token','email'],
  // can be empty (might be usefull if you need paging...)
  collectionProps: [],
  // path to your rest server
  paths: {
    item: config.signUpConfirmation,
    collection: '/'
  },
  headers: {
    "content-type": "application/json",
  },
  defaultModel: {},
  fetchOnce: false
}

export default registrationConfirmationConfig