import React  from "react"
//import PropTypes from "prop-types"


const FieldWrapper = (props) => {
  return (<div style={{ padding: "10px 0" }}>
    {props.children}
  </div>)
}

FieldWrapper.propTypes = {}
FieldWrapper.defaultProps = {}

export default FieldWrapper
