import React from "react"
import { connect } from "react-redux"
function withExtensionState(Wrapped) {
  return connect((store) => ({
    extensionAvailable: store.extension.available
  }))((props) => {
    return <Wrapped  {...props}/>
  })
}

export default withExtensionState
