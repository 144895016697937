import React from "react"
import RecipeList from "../components/recipe/components/RecipeList"
import RecipeListPage from "../layout/page/RecipeListPage"

function Home() {
  return (
    <RecipeListPage>
      <RecipeList size={100}/>
    </RecipeListPage>
  )
}

export default Home
