import globalConf from "../singletons/config"


const config = {
  extensionId: 'ckkhkdobebpelapfkckbbhacppneeobk',
  images: globalConf.backend,
  login: globalConf.api + "/login",
  signUp: globalConf.api + "/sign-up",
  signUpConfirmation: globalConf.api + "/sign-up/confirmation?token={token}&email={email}",
  profile: globalConf.api + "/user/profile",
  user: {
    teams: globalConf.api + "/user/teams"
  },
  team: {
    grantRole: globalConf.api + "/user/team/role/{team}",
    join: globalConf.api + "/user/team/join",
    invite: globalConf.api + "/user/team/invite",
    invitations: globalConf.api + "/user/team/invitations",
    leave: globalConf.api + "/user/team/leave"
  },
  logout: globalConf.api + "/logout",
  recipe: {
    team: globalConf.api + "/team/{teamId}",
    type: globalConf.api + "/recipe/type/{type}",
    recipe: globalConf.api + "/recipe",
    addToTeam: globalConf.api + "/recipe/add-to-team",
    removeFromTeam: globalConf.api + "/recipe/remove-from-team"
  },
  upload: globalConf.api + "/recipe-metas/upload"
}

export default config
