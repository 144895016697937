import React, { useState } from "react"
import FieldWrapper from "../../../layout/inputs/FieldWrapper"
import FieldComponent from "../../../layout/inputs/FieldComponent"
import useFormState from "../../hooks/useFormState"
import { inviteToTeam, popError, popSuccess } from "../actions/teamActions"
import Button from "@material-ui/core/Button"
import withToken from "../../auth/HOC/withToken"
import CalloutComponent from "../../../layout/error/CalloutComponent"

const InviteToTeam = ({ token, teamName = "Team", teamId = "", onSuccess }) => {
  const [state, setState] = useState({ error: null, pending: false, success: false })
  const [form, onChange] = useFormState({ email: "" })

  const handleJoin = () => {
    const email = form.email.trim()

    if (state.pending || state.success) return

    if (!email || !teamId) {
      popError("Please check the email field")
      return
    }

    setState({ ...state, error: null, pending: true })

    inviteToTeam({ team: teamId, email, token }).then(r => {
      setState({ error: null, pending: false, success: true })
      if (r.success === false) return Promise.reject(r)
      popSuccess(r.message)
      onSuccess()
    }).catch(r => {
      setState({ ...state, error: r.message || r, pending: false })
      popError((
        r.errors ? Object.values(r.errors).join(",") : r.message
      ) || r)
    })
  }
  return (<div style={{ padding: "20px 40px" }}>
    {state.error && (<CalloutComponent error={state.error}/>)}
    <FieldWrapper>
      <FieldComponent
        disabled={state.success || state.pending}
        autocomplete={false}
        disableUnderline={false}
        value={form.email}
        onChange={onChange}
        variant="outlined"
        onEnterKey={handleJoin}
        type="text" name="email" placeholder="Email"/>
    </FieldWrapper>
    <FieldWrapper>
      <Button
        color="primary"
        onClick={handleJoin}
        style={{ display: "block", width: "100%" }}>Invite to {teamName}</Button>
    </FieldWrapper>

  </div>)

}

InviteToTeam.propTypes = {}
InviteToTeam.defaultProps = {}

export default withToken(InviteToTeam)
