import axios from "axios"
import config from "../singletons/config"


function tweakDevEnv() {
  const isDev = process.env.NODE_ENV === "development"
  if (isDev) {
    console.log("%c-------------------", "color:#FC0")
    console.log("%cRunning in dev mode", "color:#FC0")
    console.log("%c-------------------", "color:#FC0")
  }
  return isDev
}

function loadConfig() {
  const basepath = process.env.PUBLIC_URL;
  const publicPaths = axios.get(`${basepath}/public_paths.json`);
  //const authorizations = axios.get(`${basepath}/authorizations.json`);
  //const stores = axios.get(`${basepath}/params.json`);

  // applicationList.catch((e) => {
  //     console.log('Error loading configuration')
  //     throw new Error(e)
  // })
  return Promise.all([publicPaths])
}

function pushConfig(configs, resolve) {
  configs.forEach(configFile => {
    Object.keys(configFile).map(key => {
      return (config[key] = configFile[key])
    })
  })
  resolve()
}

function getConfig() {
  const dev = tweakDevEnv()
  return new Promise((resolve, reject) => {
    loadConfig(dev).then(responses => {
      const list = responses.map(response => response.data)
      pushConfig(list, resolve)
    })
  })
}


export default function setup() {
  return getConfig()
    .then(() => {
      const store = require('./store').default
      const { reloadSessionAbstract } = require("../components/auth/actions/authStorageActions")
      return reloadSessionAbstract(store.dispatch)
    })
}
