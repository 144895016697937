import React from "react"
import FieldWrapper from "../../../../layout/inputs/FieldWrapper"
import FieldComponent from "../../../../layout/inputs/FieldComponent"

function RecipeFormArea({ recipe, changeProp, children }) {
  return (
    <div className=" animated fadeIn" style={{ padding: 20 }}>
      <FieldWrapper>
        <FieldComponent name={"name"} onChange={changeProp} value={recipe.name || ""}/>
      </FieldWrapper>

      <FieldWrapper>
        <FieldComponent type={"textarea"} name={"description"} onChange={changeProp}
                        value={recipe.description || ""}/>
      </FieldWrapper>

      <FieldWrapper>
        <FieldComponent name={"site"} onChange={changeProp} value={recipe.site || ""}/>
      </FieldWrapper>

      <FieldWrapper>
        <FieldComponent name={"rule"} onChange={changeProp} value={recipe.rule || ""}/>
        <p className="text-muted text-sm-left">(ex: www.your-site.com)</p>
      </FieldWrapper>

      {children}

      <FieldWrapper>
        <FieldComponent type={"checkbox"} name={"private"} onChange={(n, v) => changeProp(n, v ? 1 : 0)}
                        value={!!parseInt(recipe.private)}/> Private
      </FieldWrapper>

      <div className="form-group label-floating">
        {/*<label className="control-label" for="input-image">Preview Image</label>*/}
        {/*<input type="file" id="input-image"/>*/}
        {/*<input type="text" readOnly className="" style="opacity: 0" name="input-image" placeholder=""/>*/}
      </div>
    </div>
  )
}

RecipeFormArea.propTypes = {}
RecipeFormArea.defaultProps = {}

export default RecipeFormArea
