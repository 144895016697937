import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Confirm from './Confirm'

function withConfirmDialog(Wrapped) {
  return (props) => {
    const [state, setState] = useState({ confirmFn: null })
    const cancelConfirm = () => setState({ confirmFn: null })
    const onConfirm = () => {
      if (state.confirmFn) state.confirmFn()
      cancelConfirm()
    }
    const handleConfirm = (options) => {
      if (typeof options === 'function') return setState({ confirmFn: options })
      setState({
        title: options.title,
        content: options.content,
        confirmFn: options.callback
      })
    }
    return (
      <React.Fragment>
        <Confirm title={state.title} content={state.content} onAccept={onConfirm} onClose={cancelConfirm}
                 open={!!state.confirmFn}/>
        <Wrapped {...props} setConfirm={handleConfirm}/>
      </React.Fragment>
    )
  }
}

withConfirmDialog.propTypes = {
  setConfirm: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.any,
      callback: PropTypes.func
    })
  ])
}
withConfirmDialog.defaultProps = {}

export default withConfirmDialog
