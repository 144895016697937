import React  from "react"
import withToken from "../../../components/auth/HOC/withToken"
import withUser from "../../../components/auth/HOC/withUser"
import withRecipeByType from "../../../components/recipe/arc/recipeByType"
import { RecipeList } from "../../../components/recipe/components/RecipeList"
import RootRecipeList from "../../../components/recipe/components/RecipeList"
import RecipeListPage from "../../../layout/page/RecipeListPage"
import typeMap from "../../../components/recipe/types/typesMap"


const List = withToken(withUser(withRecipeByType(RecipeList)));

const TypeRecipePage = ({match}) => {
  const { type } = match.params
  return (
    <RecipeListPage>
      {!type ? (
        <RootRecipeList size={100}/>
      ) : (
        <List type={typeMap[type]} size={100}/>
      )}

    </RecipeListPage>
  )
}

TypeRecipePage.propTypes = {}
TypeRecipePage.defaultProps = {}

export default TypeRecipePage
