import React from "react"
import PublicModelContainer from "../../arc/containers/ModelContainer"
import recipeConfig from "../recipeConfig"
import { mixerConnector } from "react-arc"
import connect from "react-redux/es/connect/connect"
import ModelWithAuthContainer from "../../auth/containers/ModelWithAuthContainer"

class AuthComponentSwitcher extends React.PureComponent {
  render() {
    const { token } = this.props
    if (token) return <ModelWithAuthContainer {...this.props} />
    return <PublicModelContainer {...this.props} />
  }
}

const pushConfig = () => ({ ARCConfig: recipeConfig })

export default mixerConnector(connect, recipeConfig, pushConfig)(AuthComponentSwitcher)