import React from "react"
import { AbstractModelContainer } from "react-arc"
import { filterARCCONFIG } from "../utils"

const MissingComponent = () => (<div><h1>Missing</h1></div>)

class ModelContainer extends AbstractModelContainer {
  render() {
    const Component = this.props.component || MissingComponent
    return <Component {...filterARCCONFIG(this.props)}
                      error={this.getError()}
                      isNew={this.isNew()}
                      loaded={this.isLoaded()}
                      fetch={()=>this.fetch(this.getParams())}
                      model={this.isLoaded() ? this.getModel() : null}/>
  }
}

export default ModelContainer
