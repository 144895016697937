import React from "react"
import PropTypes from "prop-types"
import RecipeThumb from "./RecipeThumb"
import { Column } from "../../../layout/grid"
import withRecipesCollection from "../arc/recipesCollection"
import withARCLoader from "../../auth/HOC/withARCLoader"
import LazyLoadComponent from "../../../layout/lazyload/LazyLoadComponent"
import RecipeFilter from "./filter/RecipeFilter"
import Panel from "../../../layout/panel/Panel"


function deduplicate(state, item) {
  if (state.find(o => o.id === item.id)) return state
  return state.concat(item)
}

export const RecipeList = withARCLoader(({ model, size, ...props }) => {
  return (
    <React.Fragment>
      <RecipeFilter {...props}/>
      {
        model.length > 0 ? (<LazyLoadComponent
          listClassName={"row recipe-row "}
          items={model.reduce(deduplicate, []).slice(0, size).map(recipe => (
            <Column key={recipe.id} forceClassName={"col-xs-12 col-sm-6 col-md-4 col-lg-3 animated fadeInUpShort"}>
              <RecipeThumb recipe={recipe}/>
            </Column>
          ))} pageSize={10}/>) : (
            <Panel bodyStyle={{height: 200, justifyContent: 'center', alignItems:'center', display:'flex'}}>
              <h4 className="text-center">No recipes found </h4>
            </Panel>
        )
      }
    </React.Fragment>

  )
})

RecipeList.propTypes = {
  size: PropTypes.number
}
RecipeList.defaultProps = {
  size: 3
}

export default withRecipesCollection(RecipeList)
