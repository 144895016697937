export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1)
}


export function shortenLargeNumber(num, digits = 2) {
    const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
    let decimal

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1)

        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i]
        }
    }

    return num
}

/**
 *
 * @param amount
 * @param suffix
 * @param skipShorten
 * @returns {string}
 */
export function amountFormat(amount, suffix = '', skipShorten = false) {
    let value = parseFloat(amount)


    if (value % 1 !== 0) {
        value = Math.round(value * 100) / 100
    }

    if (Math.abs(amount) < 20000) {
        return value.toLocaleString() + suffix
    }

    return (skipShorten ? value : shortenLargeNumber(value)) + suffix
    //return parseFloat(amount).toLocaleString() + suffix
}


export const omit = (props, prop) => {
    const omitted = typeof prop === 'string' ? [prop] : prop
    return Object.keys(props).reduce((state, current) => omitted.includes(current) ? state : {
        ...state,
        [current]: props[current]
    }, {})
}

export const pick = (props, prop) => {
    const omitted = typeof prop === 'string' ? [prop] : prop
    return Object.keys(props).reduce((state, current) => omitted.includes(current) ? {
        ...state,
        [current]: props[current]
    } : state, {})
}



export function removeDuplicates(a) {
    return Array.from(new Set(a));
}


export function groupBy(items, keys = ['regionKey']) {
    if (!Array.isArray(keys)) {
        console.error('GroupBy Keys is not an array')
        return []
    }
    return items.reduce((state, item) => {
        //const name = item[key]
        const found = state.find(o => {
            //o.id === name
            return keys.every((key) => {
                return item[key] === o[key]
            })
        }) || keys.reduce((s, prop) => {
            return {
                ...s,
                [prop]: item[prop]
            }
        }, {
            items: []
        })
        return state.filter(o => {
            //o.id !== name
            return !keys.every((key) => {
                return item[key] === o[key]
            })
        }).concat({
            ...found,
            items: found.items.concat({
                ...item
            })
        })
    }, [])
}
