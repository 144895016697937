import {  AbstractFormModelContainer } from "react-arc"

export class FormModelWithAuthContainer extends AbstractFormModelContainer {
  // constructor(props) {
  //   super(props)
  //   this.tokenIsSet = false
  //   this.setToken(props)
  // }
  //
  // setToken(props) {
  //   const { token } = props || this.props
  //   if (!token) return
  //   this.props.ARCConfig.headers["X-Auth-Token"] = token
  //   this.tokenIsSet = true
  //   this.updateARC(this.props.ARCConfig)
  // }
  //
  // componentDidUpdate(prevProps) {
  //   if (this.tokenIsSet) {
  //     super.componentDidUpdate(prevProps)
  //     return
  //   }
  //
  //   if (this.props.token) {
  //     this.setToken(this.props.token)
  //     super.componentDidUpdate(prevProps)
  //   }
  // }
  //
  // componentDidMount() {
  //   if (this.props.token) {
  //     this.setToken(this.props)
  //     super.componentDidMount()
  //   }
  // }
}

export default FormModelWithAuthContainer
