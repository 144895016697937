import React  from "react"
//import PropTypes from "prop-types"
import AbstractAuthComponent from "../components/AbstractAuthComponent"
import {connect} from 'react-redux'
import connector from "../userAuthConnector"
import { filterARCCONFIG } from "../../arc/utils"

class UserContainer extends AbstractAuthComponent {
  render(){
    const {component} = this.props
    if (!component) return <div>missing component</div>
    const Component = component
    return (
      <Component {...filterARCCONFIG(this.props)} userChecked={this.userChecked()} user={this.getProfile()} isLogged={this.isLogged()} />
    )
  }
}

UserContainer.propTypes = {}
UserContainer.defaultProps = {}

export default connect(connector)(UserContainer)
