/* not GENERATED WITH CASTER */
//
// const defaultField = {
//     id: '',
//     value: ''
// }
const defaultState = {
    collection: []
}

export default function reducer(state = defaultState, action) {

    switch (action.type) {

        case "FIELD_STATE_SAVE_TERM": {

            const field = state.collection.find(o => o.id === action.payload.id)
            if (field) field.value = action.payload.value
            return {
                collection: field ?
                    [...state.collection] :
                    state.collection.concat([{id: action.payload.id, value: action.payload.value}])
            }
        }

        default:
            return state

    }
}
