import React from "react"
import { connect } from "react-redux"
import Signout from "./components/SignoutComponent"
import AbstractAuthComponent from "./components/AbstractAuthComponent"
import PhenixConnectComponent from "./components/user/LoginConnectComponent"
import connector from "./userAuthConnector"
import { Avatar, Dialog, Button, Icon, Divider } from "@material-ui/core"
import { Security, ExitToApp } from "@material-ui/icons"


export class DialogConnect extends React.Component {

  onChange = (valid) => {
    if (valid) this.props.close()
  }

  render() {
    return (<Dialog open={this.props.open} onClose={this.props.close}>
      <div style={{ padding: 20 }}>
        <h4>Chimp.fr Sign in</h4>
      </div>
      <Divider/>
      <PhenixConnectComponent onChange={this.onChange}/>
    </Dialog>)
  }
}


export class LdapStatusComponent extends AbstractAuthComponent {


  onlineContent = () => {
    const profile = this.getProfile(this.props)
    return profile ? (<div>
                <span className="avatar-wrapper">
                    {profile.avatar_url && profile.avatar_url !== "/" ?
                      <Avatar src={profile.avatar_url}/> :
                      <Icon className="fa fa-user"/>}
                  {this.isAdmin() ? <Security className="role"/> : null}
                </span>
      <span className="user-name"> {profile.name} </span>
      <span className="pull-right">
                    <Signout onClick={this.disconnect} style={{ lineHeight: "36px", marginLeft: "20px" }}/>
                </span>
    </div>) : null
  }

  offlineContent = () => {
    return <div>
                <span className="avatar-wrapper">
                </span>
      <span className="user-name">
                    Guest
                </span>
      <span className="pull-right">
                <Button onClick={this.props.openAuth} icon={<ExitToApp/>} label={"Sign in"}/>
            </span>
    </div>
  }

  render() {
    return this.isLogged() ? this.onlineContent() : this.offlineContent()
  }
}

const LdapStatus = connect(connector)(LdapStatusComponent)


export default class LdapComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      authVisible: false
    }
  }

  closeAuth = () => this.setState({ authVisible: false })

  openAuth = () => this.setState({ authVisible: true })

  render() {
    return (<div>
      <DialogConnect open={this.state.authVisible} close={this.closeAuth}/>
      <div className={"footer animated fadeIn"}>
        <LdapStatus openAuth={this.openAuth}/>
      </div>
    </div>)
  }
}