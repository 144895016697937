import React from "react"
import { Button } from "@material-ui/core"
import { Check, CloudUpload, Delete, FlashOn, Pause, PlayArrow } from "@material-ui/icons"
import { Column, Row } from "../../../../layout/grid"
import actions from './extension-actions'
import withExtensionState from "../../../extension/HOC/withExtensionState"

const styles = {
  icon: {
    marginRight: 10
  }
}
const ExtensionRecipeToolbar = ({ installed, enabled, recipe, extensionAvailable }) => {
  if(!extensionAvailable) return null

  if (!installed) {
    return (
      <div className="text-center">
        <Button
          size={"small"}
          variant={"contained"}
          color={"primary"}
          className={"snowflake-button-gradient"}
          onClick={() => actions.install({recipe})}
        >
          <FlashOn style={styles.icon}/> add
        </Button>
      </div>
    )
  }

  const recipeIsSynced = (installed.js === recipe.js) && (installed.css === recipe.css)
  return (
    <Row className="no-gutters">
      <Column size={7}>
        {enabled ? (
          <Button
            disableElevation
            variant={"contained"}
            color={"primary"}
            onClick={() => actions.disable({recipe})}
            style={styles.icon}>
            <Pause style={{ fontSize: 16 }}/>
          </Button>
        ) : (
          <Button
            variant={"contained"} color={"primary"}
            disableElevation
            onClick={() => actions.enable({recipe})}
            style={styles.icon}>
            <PlayArrow style={{ fontSize: 16 }}/>
          </Button>
        )}


        <Button
          disableElevation
          variant={"contained"}
          color={"primary"}
          onClick={() => actions.remove({recipe})}
          style={styles.icon}
        >
          <Delete style={{ fontSize: 16 }}/>
        </Button>

      </Column>
      <Column size={5} className="text-right">
        {recipeIsSynced ? (
          <Button
            disableElevation
            size={"small"}
            variant={"contained"}
            className="animated fadeIn snowflake-button-gradient-synced" color={"primary"}
            onClick={() => actions.install({recipe})}
          >
            <Check style={{ fontSize: 16, marginRight: 5 }}/> Up to date
          </Button>
        ):(
          <Button
            size={"small"}
            variant={"contained"} className={"animated fadeIn snowflake-button-gradient"} color={"primary"}
            onClick={() => actions.install({recipe})}
          >
            <CloudUpload style={{ fontSize: 16, marginRight: 10 }}/> update
          </Button>
        )}

      </Column>

    </Row>
  )
}


export default withExtensionState(ExtensionRecipeToolbar)
