import axios from 'axios'
import config from '../../../config/config'


export function imageUpoad(token, formData) {
  if (!token) Promise.reject()
  return axios({
    url: config.upload,
    //dataType: 'json',
    method: 'post',
    headers: {
      "Content-Type": "application/json",
      "X-Auth-Token": token
    },
    // contentType: false,
    // processData: false,
    data: formData
  });
}