import React, { PureComponent } from "react"
import PropTypes from 'prop-types'


/** TODO
 * REFACTOR ITS A MESS !
 * **/

export class Column extends PureComponent {
    render() {
        const {size, className, forceClassName, style, children} = this.props
        const _className = forceClassName || `col-md-${size} col-sm-${size} col-xs-${size} ${className}`
        return (<div className={_className} style={style}>
            {children}
        </div>)
    }
}

Column.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string,
    forceClassName: PropTypes.string,
    style: PropTypes.object
};
Column.defaultProps = {
    size: 4,
    className: '',
    forceClassName: '',
    style: {}
};

export default Column;
