import React from "react"
import Page from "../../../layout/page/Page"
import CalloutComponent from "../../../layout/error/CalloutComponent"
import Loader from "../../../layout/loader/Loader"


function withPageLoading(Wrapped) {
  return (props) => {
    const { error, loaded } = props
    if (error || !loaded) {
      return (<Page>
        {error && (<CalloutComponent error={error}/>)}
        {!loaded && !error && (<Loader/>)}
      </Page>)
    }
    return <Wrapped {...props}  />
  }
}


withPageLoading.propTypes = {}
withPageLoading.defaultProps = {}

export default withPageLoading