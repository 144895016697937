import { connect } from "react-redux"
import PropTypes from 'prop-types'
import { mixerConnector } from "react-arc"
import { compose } from "redux"
import ModelContainer from "../../arc/containers/ModelContainer"
import withToken from "../../auth/HOC/withToken"
import createHOC from "../../arc/createHOC"
import config from "../../../config/config"


/** DONT FORGET TO :
 [ ] DECLARE YOUR store : config/store.js  -> recipeByType
 [ ] ADD MODEL REQUIRED PROPS, in configRecipeByType
 [ ] TYPE THEM  const types = {id: PropType.string.isRequired} (for instance
 [ ] IMPORT ARCModel, createHOC, config (for your urls), from where you are in the project (use your IDE)
 **/

export const RECIPE_BY_TYPE_NAMESPACE = "RECIPE_BY_TYPE"

export const configRecipeByType = {
  name: "recipeByType",
  uppercaseName: RECIPE_BY_TYPE_NAMESPACE,
  modelProps: ['type', 'token'],
  collectionProps: [],
  paths: {
    item: config.recipe.type
  },
  headers: {
    Accept: "application/json",
    "X-Auth-Token": "{token}"
  },
  fetchOnce: false,
  maxPendingRequestsPerReducer: 3,
  requestFetchDelay: 100,
  defaultModel: []
}

const types = {
  id: PropTypes.string
}

export class recipeByTypeContainerBase extends ModelContainer {
  static defaultProps = {
    ARCConfig: configRecipeByType,
    token: ''
  }
  static propTypes = types
}

const connector = mixerConnector(connect, configRecipeByType)
export const recipeByTypeContainer = compose(withToken, connector)(recipeByTypeContainerBase)

const withRecipeByType = createHOC({
  Container: recipeByTypeContainer,
  ARCConfig: configRecipeByType
})
withRecipeByType.propTypes = types

withRecipeByType.defaultProps = {}

export default withRecipeByType