import config from "../../config/config"

export const recipeConfig = {
  name: "recipe",
  //used in the reducers
  uppercaseName: "RECIPE",
  // useful to map objects in collection
  modelProps: ["id", "token"],
  // can be empty (might be usefull if you need paging...)
  collectionProps: ["token"],
  // path to your rest server
  paths: {
    item: config.recipe.recipe + "/{id}",
    collection: config.recipe.recipe
  },
  headers: {
    "content-type": "application/json",
    "X-Auth-Token": "{token}"
  },
  defaultModel: {
    "name": "New Recipe",
    "site": "https://",
    "media": "",
    "description": "",
    "rule": "",
    "css": "",
    "js": "",
  },
  fetchOnce: false
}

export default recipeConfig