import React, { useEffect, useRef, useState } from "react"
import { compose } from "redux"
import withRecipe from "../../../components/recipe/HOC/withRecipe"
import CalloutComponent from "../../../layout/error/CalloutComponent"
import Loader from "../../../layout/loader/Loader"
import { Button, AppBar, Tabs, Tab } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import RecipeImage from "../../../components/recipe/components/RecipeImage"
import withUser from "../../../components/auth/HOC/withUser"
import { extractParams, mixerConnector } from "react-arc"
import { connect } from "react-redux"
import recipeConfig from "../../../components/recipe/recipeConfig"
import FormModelWithAuthContainer from "../../../components/auth/containers/FormModelWithAuthContainer"

import { addToast } from "re-toaster/"
import navigate from "../../../singletons/navigate"
import withToken from "../../auth/HOC/withToken"
import "../assets/recipe.scss"
import { imageUpoad } from "../actions/recipeActions"
import FieldWrapper from "../../../layout/inputs/FieldWrapper"
import { Column, Row } from "../../../layout/grid"
import withBridge from "../HOC/withBridge"
import withPageLoading from "../../auth/HOC/withPageLoading"
import RecipeSideBar from "./sidebar/RecipeSideBar"
import SideBarSection from "./sidebar/SideBarSection"
import { ArrowBackIos, FullscreenExit, Fullscreen } from "@material-ui/icons"
import EditorsArea from "./editor/EditorsArea"
import TeamArea from "./editor/TeamArea"
import RecipeFormArea from "./editor/RecipeFormArea"
import { useFullScreen } from "../../hooks/fullscreen"
import withExtensionData from "../../extension/HOC/withExtensionData"
import config from "../../../config/config"
import Panel from "../../../layout/panel/Panel"

const styles = {
  tab: {
    fontSize: 11,
    letterSpacing: 1,
    fontWeight: 700,
    fontFamily: "'Manrope', Helvetica, Roboto,  Arial, sans-serif"
  }
}


const RecipeImagePreview = ({recipe, size=""}) => {
  if(!recipe.media) return null
  const maxSize = 200
  const source = config.images + "/images/" + (size === "large" ? "original/" : "") + recipe.media
  return (
    <Panel style={{display:"inline-block"}} bodyStyle={{padding:0, display:"inline-block"}}>
      <img src={source} alt={recipe.name} style={{maxHeight: maxSize, maxWidth: maxSize}} />
    </Panel>
    )
}


function Toolbar({ canEdit, toggleEdit }) {
  if (!canEdit) return <div/>
  return (
    <div className="text-center">
      <Button disableElevation size={"small"} variant="contained" color="primary" onClick={toggleEdit}>
        <Edit style={{ fontSize: 14, marginRight: 10 }}/> Edit
      </Button>
    </div>
  )
}

function MainSection({ recipe, children, style }) {
  return (
    <Column forceClassName={"col"} style={{ padding: 0, ...(style) }}>
      {children}
      <script type="application/snowflake-data" data-snowflake={recipe.id}>{JSON.stringify(recipe)}</script>
    </Column>
  )
}

function SideSection({ children, style, toggleSideBar, sidebarVisible, width = 300 }) {
  return (<Column forceClassName={"col chimp-sidebar"} style={style || { maxWidth: 300 }}>
    {!!toggleSideBar && (<div className="button-toggle-sidebar" onClick={toggleSideBar}>
      <div className={"button-toggle-sidebar-icon"} style={{
        transform: "rotate(" + (sidebarVisible ? "180" : "0") + "deg) scale(" + (sidebarVisible ? "1" : "1.3") + ")"
      }}>
        <ArrowBackIos style={{ fontSize: "16px" }}/>
      </div>
    </div>)}
    {children}

    {/*<div style={{ width, height: "100%" }}>*/}
    {/*  */}
    {/*</div>*/}
  </Column>)
}

//
// const FormTitleText = ({ children }) => (
//   <strong style={{ fontWeight: 700 }} className="control-label">{children}</strong>)

function RecipeDisplay({ recipe, canEdit, toggleEdit, isNew, found, userInfo, enabled }) {
  return (
    <Row className="no-gutters animated-column">
      <MainSection recipe={recipe}>
        <div className="recipe-container">
          <div className="showing animated fadeIn" style={{ backgroundColor: "#f6f9fc" }}>
            <RecipeImage size={"large"} recipe={recipe}/>
          </div>
        </div>
      </MainSection>
      <SideSection>
        <RecipeSideBar isNew={isNew} userInfo={userInfo} found={found} enabled={enabled} recipe={recipe}>
          {!!canEdit && (<SideBarSection title="Recipe controls" togglable={false} style={{ flex: "none" }}>
            <Toolbar toggleEdit={toggleEdit} canEdit={canEdit}/>
          </SideBarSection>)}
        </RecipeSideBar>
      </SideSection>
    </Row>
  )
}


const fsStyles = {
  button: {
    position: "absolute",
    top: 10,
    right: 10,
    zIndex: 1,
    backgroundColor: "rgba(255,255,255,.1)",
    color: "rgba(255,255,255,.5)"
  }
}
const FullScreenContainer = ({ children, style, className }) => {
  const element = useRef(null)
  const fs = useFullScreen({ element })
  const toggleFullScreenButton = (
    <Button size={"small"} onClick={fs.toggle} variant={"contained"} style={fsStyles.button}>
      {!fs.fullScreen ? (<Fullscreen fontSize={"small"}/>) : (<FullscreenExit fontSize={"small"}/>)}
    </Button>)
  return (<div ref={element} className={className} style={{ ...style, position: "relative" }}>
    {children({ toggleFullScreenButton })}
  </div>)
}


const RecipeEdit = mixerConnector(connect, recipeConfig, (store) => ({
  token: store.auth.user.token
}))(class RecipeEditContainer extends FormModelWithAuthContainer {
  static defaultProps = {
    ARCConfig: recipeConfig
  }

  constructor(props) {
    super(props)
    this.state = {
      mode: "editor",
      allowNotification: false,
      isUploading: false,
      uploadError: false,
      sidebarVisible: true
    }
    this.imageInputRef = React.createRef()
  }

  toggleSideBar = (status) => {
    this.setState((previousState) => {
      return {
        sidebarVisible: typeof status === "boolean" ? status : !previousState.sidebarVisible
      }
    })
  }

  onSave({ id, isNew }) {


    if (this.state.allowNotification) {
      /** TODO CHAIN PROMISES **/

      //const recipe = created || this.getModel()

      if (this.imageInputRef && this.imageInputRef.current && this.imageInputRef.current.files[0] && !this.state.isUploading && !this.state.uploadError) {

        const file = this.imageInputRef.current.files[0]
        const formData = new FormData()
        formData.append("userfile", file)
        formData.append("recipe", id)
        formData.append("title", this.getModel().name)

        this.setState({ isUploading: true })

        imageUpoad(this.props.token, formData)
          .then(o => {
            this.props.dispatch(addToast({
              type: "success",
              icon: "fa fa-check",
              message: "Image Successfully Saved !"
            }))
            if(this.state.isNew) {
              navigate("/recipe/" + id)
            } else {
              this.fetch(this.getParams())
            }
            this.setState({ isUploading: false, allowNotification: false, isNew: false })

          })
          .catch(e => {
            this.setState({ isUploading: false, uploadError: true })
            console.log("error")
            console.log(e)
          })
      } else {
        this.props.dispatch(addToast({
          type: "success",
          icon: "fa fa-check",
          message: "Recipe Successfully Saved !"
        }))
        if(this.state.isNew) {
          navigate("/recipe/" + id)
        }
        this.setState({ isUploading: false, allowNotification: false, isNew: false })
      }
    }
  }

  changeTab = (e, v) => {
    this.setState({ mode: v })
  }

  save = () => {
    const isNew = this.isNew()
    const model = this.getModel()
    const extracted = extractParams(this.ARCConfig.modelProps, this.props)
    const params = { ...extracted, ...(isNew ? this.getParams(model) : this.getParams()) }
    this.props.dispatch(this.actions.save(model, params, isNew))
  }

  prepareSave = () => {
    this.setState({
      isNew: this.isNew(),
      allowNotification: true,
      uploadError: false,
      isUploading: false
    })
    this.save()
  }

  prepareRemove = () => {
    this.remove()
    this.props.dispatch(addToast({
      type: "success",
      icon: "fa fa-check",
      message: "Recipe Successfully Removed !"
    }))
    navigate("/recipes")
  }

  switchContent = () => {
    const { mode } = this.state
    const recipe = this.getModel()
    switch (mode) {

      case "editor": {
        return (
          <EditorsArea recipe={recipe} changeProp={this.changeProp}/>
        )
      }
      case "teams": {
        return (<TeamArea changeProp={this.changeProp} recipe={recipe}/>)
      }

      default: {
        return (
          <RecipeFormArea changeProp={this.changeProp} recipe={recipe}>
            <RecipeImagePreview recipe={recipe} />

            <FieldWrapper>
              <input ref={this.imageInputRef} type="file" id="input-image"/>
              <input type="text" readOnly className="" style={{ opacity: 0 }} name="input-image" placeholder=""/>
            </FieldWrapper>
          </RecipeFormArea>
        )
      }
    }
  }

  render() {
    const error = this.getError()
    const loaded = this.isLoaded()

    if (error) return <CalloutComponent error={error}/>
    if (!loaded) return <Loader/>

    const { isNew, found, userInfo, enabled } = this.props
    const { sidebarVisible } = this.state
    const recipe = this.getModel()

    const width = sidebarVisible ? "300px" : "5px"

    return (
      <FullScreenContainer style={{ height: "100%", backgroundColor: "#f4f6f9" }}>
        {({ toggleFullScreenButton }) => {
          return (<Row className="no-gutters animated-column" style={{ height: "100%" }}>
            <MainSection recipe={recipe}>
              <div className="recipe-container">
                <div className="editing animated fadeIn" style={{ paddingTop: 48 }}>
                  <AppBar position="absolute">
                    <Tabs style={{ backgroundColor: "#4c5269" }} value={this.state.mode} onChange={this.changeTab}>
                      <Tab style={styles.tab} value={"editor"} label="Editor"/>
                      <Tab style={styles.tab} value={"info"} label="Info"/>
                      <Tab style={styles.tab} value={"teams"} label="Teams"/>
                    </Tabs>
                    {toggleFullScreenButton}
                  </AppBar>
                  {this.switchContent()}
                </div>
              </div>
            </MainSection>
            <SideSection sidebarVisible={sidebarVisible} toggleSideBar={this.toggleSideBar} style={{ maxWidth: width }}>
              <RecipeSideBar isNew={isNew} userInfo={userInfo} found={found} enabled={enabled} recipe={recipe}>
                <SideBarSection togglable={false} title="Recipe controls" style={{ flex: "none", overflow: "hidden" }}>
                  <Row className="no-gutters">
                    <Column size={4}>
                      {recipe.id && (
                        <Button size={"small"} onClick={this.prepareRemove} color={"secondary"}>delete</Button>)}
                    </Column>
                    <Column size={8} className="text-right">
                      <Button size={"small"} onClick={() => this.props.toggleEdit()}
                              style={{ backgroundColor: "#FFF", marginRight: 20 }}>Cancel</Button>
                      <Button size={"small"} variant={"contained"} color={"primary"}
                              disableElevation
                              onClick={() => this.prepareSave()}>save</Button>
                    </Column>
                  </Row>
                </SideBarSection>
              </RecipeSideBar>
            </SideSection>
          </Row>)
        }}
      </FullScreenContainer>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.creation && this.props.id) {
      this.resetTempModel()
      return this.onSave({ id: this.props.id })
    }

    if (this.getMetas("saved")) {
      return this.onSave(this.getModel())
    }
  }
})


const getRecipeUserInfo = (user, recipe, isNew) => {
  return isNew ? {
    author_name: user.username,
    first_name: user.first_name,
    last_name: user.last_name,
    avatar: user.avatar
  } : {
    author_name: recipe.author_name,
    first_name: recipe.author_data.first_name,
    last_name: recipe.author_data.last_name,
    avatar: recipe.author_data.avatar
  }
}

const Recipe = ({ recipe, isNew, user, creation, error, loaded, extensionData, isLogged }) => {


  const [state, setState] = useState({
    editing: false
  })


  const recipeStatus = () => {
    const state = {
      found: false,
      enabled: true
    }
    if (isNew || error || !loaded) return state
    extensionData.forEach(item => {
      if (recipe.id === item.id) {
        state.found = item
        if (item.disabled) state.enabled = false
      }
    })
    return state
  }

  const toggle = () => {
    setState({ editing: !state.editing })
  }
  const grantedDueToTeam = () => {
    if (!isLogged) return false
    return user.teams.some(user_team => {
      return recipe.teams.some(recipe_team => {
        return user_team.id === recipe_team.id
      })
    })
  }

  const canEdit = () => {
    return isLogged && (user.id === recipe.author || grantedDueToTeam())
  }

  const { enabled, found } = recipeStatus()

  const editing = isNew || (canEdit() && state.editing)

  const userInfo = getRecipeUserInfo(user, recipe, isNew)

  useEffect(() => {
    recipeStatus()
  })

  const commons = {
    found,
    enabled,
    userInfo,
    isNew,
    toggleEdit: toggle
  }

  if (editing) {
    return <RecipeEdit {...commons} creation={creation} id={recipe.id}/>
  }

  return (
    <RecipeDisplay {...commons} canEdit={canEdit()} recipe={recipe}/>
  )
}


export default compose(withToken, withRecipe, withBridge, withUser, withPageLoading, withExtensionData)(Recipe)
