import React, { PureComponent } from "react"
//import PropTypes from "prop-types"
import { Button } from "@material-ui/core"
import { CloudDownload } from "@material-ui/icons"


export class Jumbo extends PureComponent {
  render() {
    return (<div className="home-jumbo">
      {this.props.children}
    </div>)
  }
}

const Welcome = () => (<Jumbo>
  <div className="jumbotron-home">
    <div className="container">
      <section id="about" className="section">
        <h1 className="grey-text-3 font-bold">
          <strong>Create your own recipes</strong><br /> and start enhancing your websites
        </h1>
        <p className="grey-text-3">
          Welcome ! <br />Chimp Lets you customize all the websites and share your customizations across the web
          </p>
        <Button target="_blank"
                style={{ backgroundColor: "#FFF" }}
                variant={"contained"}
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/chimpfr/ckkhkdobebpelapfkckbbhacppneeobk"
        >
          <CloudDownload style={{ marginRight: 10 }}/>
          Get The Chrome Extension
        </Button>
      </section>
    </div>
  </div>
</Jumbo>)


Welcome.propTypes = {}
Welcome.defaultProps = {}

export default Welcome
