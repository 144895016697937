import React, { useState } from "react"
//import PropTypes from "prop-types"
import { Column, Row } from "../../../../layout/grid"
import EditorTypeTitle from "./EditorTypeTitle"
import AceEditor from "react-ace"
import "brace"
import "brace/mode/javascript"
import "brace/mode/css"
import "brace/theme/monokai"
import "brace/ext/language_tools"

const TAB_TYPES_HEIGHT = "30px"

const editorBaseProps = {
  theme: "monokai",
  style: {
    width: "100%",
    height: "calc(100% - " + TAB_TYPES_HEIGHT + ")"
  },
  fontSize: 12,
  showPrintMargin: false,
  showGutter: true,
  highlightActiveLine: false,
  editorProps: {
    $blockScrolling: Infinity
  },
  setOptions: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2
  }
}

function EditorsArea({ changeProp, recipe }) {
  const [editorFocusState, setEditorSize] = useState({ js: true, css: true })

  const isDefaultState = Object.values(editorFocusState).every(p => p === true)

  const setEditorFocus = (editor) => {
    const newState = Object.keys(editorFocusState).reduce((s, prop) => {
      return {
        ...s,
        [prop]: isDefaultState ? false : editorFocusState[editor] === true
      }
    }, {})
    setEditorSize({
      ...newState,
      [editor]: true
    })
  }


  const sizeMinimizedStyle = { maxWidth: "5%" }
  return (
    <Row className="no-gutters animated-column-500 animated fadeIn">
      <Column forceClassName={"col"} style={!editorFocusState.css ? sizeMinimizedStyle : undefined}>
        <EditorTypeTitle isDefaultState={isDefaultState} toggleState={() => setEditorFocus("css")}
                         state={editorFocusState.css} title={"Style"} type={"CSS"}/>
        <AceEditor
          {...editorBaseProps}
          mode="css"
          name="css"
          onChange={(v) => changeProp("css", v)}
          value={recipe.css}
        />
      </Column>
      <Column forceClassName={"col"} style={!editorFocusState.js ? sizeMinimizedStyle : undefined}>
        <EditorTypeTitle isDefaultState={isDefaultState} toggleState={() => setEditorFocus("js")}
                         state={editorFocusState.js} title={"Javascript"} type={"ES6"}/>
        <AceEditor
          {...editorBaseProps}
          mode="javascript"
          name="js"
          onChange={(v) => changeProp("js", v)}
          value={recipe.js}
        />
      </Column>

    </Row>
  )
}

EditorsArea.propTypes = {}
EditorsArea.defaultProps = {}

export default EditorsArea
