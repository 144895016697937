import React from 'react'
import {Button,} from '@material-ui/core'
import {ExitToApp} from '@material-ui/icons'
import {signout} from 'react-redux-oauth2'

const SignoutComponent = signout(class extends React.Component {
    render() {
        return <Button onClick={this.props.onClick} icon={<ExitToApp/>} label={'Sign out'}/>
    }
})

export default SignoutComponent

