import React from "react"

/** REDUX **/
import { connect, Provider } from "react-redux"
import store from "./config/store"
import { ARCProvider } from "react-arc"

/** THEME **/
import router from "./config/router"
import ClientComponent from "./components/client/component/ClientComponent"
import withRoot from "./withRoot"
import ChimpExtensionProvider from "./components/extension/provider/ChimpExtensionProvider"


const App = () => {
  return (
    <Provider store={store}>
      <ARCProvider store={store} connect={connect}>
        <ClientComponent>
          <ChimpExtensionProvider store={store}>
            {router}
          </ChimpExtensionProvider>
        </ClientComponent>
      </ARCProvider>
    </Provider>
  )

}

export default withRoot(App)
