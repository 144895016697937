import React from "react"
import { TextField, withStyles, Checkbox } from "@material-ui/core"
import PropTypes from "prop-types"

const styles = {
  underline: {}
}

export class FieldComponent extends React.Component {
  up(e) {
    const { name, onChange, type } = this.props
    if (!name) {
      console.error("please provide name to your input component")
      return
    }
    if (onChange)
      onChange(name, type === "checkbox" ? e.target.checked : e.target.value, e)
  }

  onEnterKey(e) {
    if (this.props.onEnterKey && (e.key || e.keyCode) === "Enter") this.props.onEnterKey()
  }

  component() {
    const { value, type, gotError, name, placeholder, classes, rowsMax, variant, disabled, autoComplete } = this.props

    const style = this.props.style || {}
    const className = (this.props.className || "") + (gotError ? " has-error" : "")


    const text = (<TextField type={type}
                             InputProps={{
                               underline: classes.underline
                             }}
                             autoComplete={autoComplete}
                             disabled={disabled}
                             fullWidth={true}
                             value={value}
                             variant={variant}
                             name={name}
                             onKeyPress={(e) => this.onEnterKey(e)}
                             onChange={(e, e2, e3) => this.up(e, e2, e3)}
                             required={this.props.required}
                             readOnly={this.props.disabled}
                             onBlur={this.props.onBlur || null}
                             label={placeholder || name}
    />)

    switch (type) {
      case "number":
      case "password":
      case "textonly":
      case "text": {
        return text
      }


      case "textarea": {


        return (<TextField
          InputProps={{
            underline: classes.underline
          }}
          disabled={disabled}
          rowsMax={rowsMax}
          multiline
          fullWidth={true}
          value={value}
          variant={variant}
          name={name}
          onKeyPress={(e) => this.onEnterKey(e)}
          onChange={(e, e2, e3) => this.up(e, e2, e3)}
          required={this.props.required}
          readOnly={this.props.disabled}
          onBlur={this.props.onBlur || null}
          label={placeholder || name}
        />)


      }
      case "checkbox": {

        return <Checkbox
          checked={!!value}
          className={className}
          name={name}
          disabled={disabled}
          style={style}
          variant={variant}
          required={this.props.required}
          readOnly={this.props.disabled}
          onChange={e => this.up(e)}
          value={name}
        />
        // return (<input type="checkbox"
        //                className={className}
        //                name={name}
        //                style={style}
        //                value={!!value}
        //                checked={!!value}
        //                onChange={e => this.up(e)}
        //                required={this.props.required}
        //                readOnly={this.props.disabled}
        // />)
      }
      default: {
        return text
      }
    }
  }

  render() {
    return this.component()
  }
}

FieldComponent.propTypes = {
  type: PropTypes.oneOf(["number", "password", "text", "textonly", "textarea", "checkbox"]),
  value: PropTypes.any,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  gotError: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
  //disableUnderline: PropTypes.bool,
  rowsMax: PropTypes.number,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.bool
}
FieldComponent.defaultProps = {
  extendedProps: {},
  //disableUnderline: true,
  autocomplete: true,
  rowsMax: 4
}

export default withStyles(styles)(FieldComponent)