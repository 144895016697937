import React, {  useState } from "react"
import { Avatar } from "@material-ui/core"
import withARCLoader from "../../../auth/HOC/withARCLoader"
import withUserTeams from "../../../user/arc/UserTeams"
import { compose } from "redux"
import withUser from "../../../auth/HOC/withUser"
import { Column, Row } from "../../../../layout/grid"
import Panel from "../../../../layout/panel/Panel"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Loader from "../../../../layout/loader/Loader"
import { addToast } from "re-toaster/lib/actions/toasterActions"
import store from '../../../../config/store'
import withStatefulRecipe from "../../HOC/withStatefulRecipe"




const NoTeams = () => {
  return <div className="animated fadeIn text-center" style={{padding: 20}}>
    <h5 className="text-muted">No other teams</h5>
    <p className="text-muted">your recipe is visible to no other team</p>
  </div>
}

const NoMoreTeamAvailable = () => {
  return <div className="animated fadeIn text-center" style={{padding: 20}}>
    <h5 className="text-muted">No other teams available</h5>
  </div>
}

const Team = ({ team, onClick }) => {
  return <ListItem className="animated fadeIn" button onClick={onClick}>
    <ListItemIcon>
      <Avatar>{team.name[0]}</Avatar>
    </ListItemIcon>
    <ListItemText primary={team.name}/>
  </ListItem>
}



const Teams = compose(withUserTeams, withARCLoader)(({ model: userTeams, recipe, addRecipeToTeam, removeRecipeFromTeam, refresh }) => {

  const [state, setState] = useState({ error: null, pending: false })
  const requestHandler = (request) => {
    if(state.pending) return ;
    setState({error: null, pending: true})
    request()
      .then(r => {
        setState({error: null, pending: false})
        if(r.success === false) return Promise.reject(r)
        store.dispatch(addToast({ type: "success", icon: "fa fa-check", message: r.message }))
        refresh()
      })
      .catch(error => {
        store.dispatch(addToast({ type: "error", icon: "fa fa-warning", message: error.message }))
        setState({error: error, pending: false})
      })
  }

  const userTeamsAvailable = userTeams.filter( t => !recipe.teams.map(t => t.id).includes(t.id) )

  return <div style={{position: 'relative'}}>
    <div style={{position: 'absolute', width: '100%', top: 50}}>
      {state.pending && <Loader />}
    </div>

    <Row>
      <Column size={6}>
        <Panel title="Your recipe is visible to theses teams" bodyStyle={{padding:0}}>
          {recipe.teams.length < 1 && (<NoTeams/>)}
          {recipe.teams.map(team => (
            <Team key={team.id} team={team} onClick={() => {
              requestHandler(() => removeRecipeFromTeam({
                recipeId: recipe.id, teamId: team.id
              }))
            }}/>
          ))}
        </Panel>
      </Column>
      <Column size={6}>
        <Panel title="Your Teams" bodyStyle={{padding:0}}>
          {userTeamsAvailable.length < 1 && (<NoMoreTeamAvailable/>)}
          {userTeamsAvailable.map(team => (
            <Team key={team.id} team={team} onClick={() => {
              requestHandler(() => addRecipeToTeam({
                recipeId: recipe.id, teamId: team.id
              }))
            }}/>
          ))}
        </Panel>
      </Column>
    </Row>
  </div>
})

const RecipeWrapper = compose(withStatefulRecipe, withARCLoader)(({recipe, fetchRecipe, children}) => {
  return children({refresh: fetchRecipe, recipe})
})

function TeamArea({ recipe, changeProp, children, user }) {
  // USE PREVIOUS
  return (
    <div className={" animated fadeIn"} style={{ padding: "40px 20px" }}>
      <h4 style={{marginBottom: 30}}>Teams</h4>
      <div>
        <RecipeWrapper defaultRecipe={recipe} id={recipe.id}>
          {({ refresh, recipe }) => {
            return (<Teams
              refresh={refresh}
              recipe={recipe}
              userId={user.id}
            />)
          }}
        </RecipeWrapper>

      </div>
    </div>
  )
}

TeamArea.propTypes = {}
TeamArea.defaultProps = {}

export default withUser(TeamArea)
