import Unique from '../../../utils/Unique'
const KEY = "yago/clientID"

export function loadClient() {
    let clientID = localStorage.getItem(KEY)
    if (!clientID) {
        clientID = new Unique().gen()
        localStorage.setItem(KEY, clientID)
    }

    return function (dispatch) {
        dispatch({type: 'CLIENT_INIT', payload: {clientID}})
    }
}
export function destroyClient() {
    localStorage.setItem(KEY, null)
    return function (dispatch) {
        dispatch({type: 'CLIENT_DESTROY', payload: {}})
    }
}