import React, { useEffect, useRef, useState } from "react"

import withToken from "../../auth/HOC/withToken"
import { useFetch } from "use-http"
import config from "../../../config/config"


const withStatefulRecipe = (Wrapped) => {
  return withToken(({id, defaultRecipe = {}, token, ...props}) => {
    const [loaded, setLoaded] = useState(false)
    const [recipe, setRecipe] = useState(defaultRecipe)
    const { get, response, loading, error } = useFetch(config.recipe.recipe, {
      cacheLife: 0,
      cachePolicy: 'no-cache',
      headers: {
        "content-type": "application/json",
        "X-Auth-Token": token
      },
    })

    const fetch = useRef(async function () {
      const recipe = await get('/' + id)
      if (response.ok) {
        setLoaded(true)
        setRecipe({ ...recipe })
      }
    })

    useEffect(() => {
      fetch.current()
      return () => {
        setLoaded(false)
      }
    }, [fetch]) // componentDidMount

    return (
      <React.Fragment>
        <Wrapped {...props} loaded={loaded} fetchRecipe={fetch.current} recipe={recipe} loading={loading} error={error} />
      </React.Fragment>
    )
  })
}

withStatefulRecipe.propTypes = {}
withStatefulRecipe.defaultProps = {}

export default withStatefulRecipe
