import React from "react"
import Header from "../header/Header"
import SideBarComponent from "../sidebar/SideBarComponent"
import "./application.scss"
import { ToasterComponent } from "re-toaster"
import theme from "../theme/toaster"
import useToggle from "../../components/hooks/useToggle"
import withUser from "../../components/auth/HOC/withUser"
import Footer from "../footer/Footer"


function ApplicationLayout({ children, userChecked }) {
  const [open, toggle] = useToggle(false)
  return (<div className="App with-animations">
    <SideBarComponent open={open} toggler={toggle}/>
    <Header toggler={toggle}/>
    <ToasterComponent theme={theme}/>
    <div className={"module-content" + (open ? " offset" : "")}>
      {children}
    </div>
    <Footer />
  </div>)
}


export default withUser(ApplicationLayout)