import React from "react"
import { connect } from "react-redux"
function withRecipeExtensionState(Wrapped) {
  return connect((store, { recipe }) => {
    return ({
      recipeInstalled: store.extension.collection[recipe.id]
    })
  })((props) => {
    return <Wrapped  {...props}/>
  })
}

export default withRecipeExtensionState
