import React from "react"
import Page from "../../../layout/page/Page"
import CalloutComponent from "../../../layout/error/CalloutComponent"


function withUserCheck(Wrapped) {
  return (props) => {
    const { /*isNew,*/ user } = props
    if (!user) {
      return (<Page>
        <CalloutComponent title={"You must sign in first"}/>
      </Page>)
    }
    return <Wrapped {...props}  />
  }
}


withUserCheck.propTypes = {}
withUserCheck.defaultProps = {}

export default withUserCheck