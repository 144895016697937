import React from "react"

/** REDUX **/
//import store from './store'

/** ROUTES DEPENDENCIES **/
import { Router, Route } from "react-router"
import { ConnectedRouter } from "connected-react-router"

import history from "../singletons/history"

/** LAYOUT COMPONENTS **/
import ApplicationLayout from "../layout/application/ApplicationLayout"
import RouterHome from "../components/home/RouterHome"
import RouterRecipe from "../components/recipe/RouterRecipe"

import SignUpPage from "../pages/SignUpPage"
import SignInPage from "../pages/SignInPage"
import SettingsProfilePage from "../pages/SettingsProfilePage"
import SettingsTeamsPage from "../pages/SettingsTeamsPage"
import SettingsTeamJoinPage from "../pages/SettingsTeamJoinPage"
import SignUpConfirmPage from "../pages/SignUpConfirmPage"

/** Pages **/
//basename={process.env.PUBLIC_URL}
const router = (
  <ConnectedRouter  history={history}>
    <Router history={history}>
      <ApplicationLayout>
        <Route path="/" component={RouterRecipe}/>
        <Route exact path="/" component={RouterHome}/>
        <Route exact path="/sign-up/confirm" component={SignUpConfirmPage}/>
        <Route exact path="/sign-up" component={SignUpPage}/>
        <Route exact path="/sign-in" component={SignInPage}/>
        <Route exact path="/settings/profile" component={SettingsProfilePage}/>
        <Route exact path="/settings/teams" component={SettingsTeamsPage}/>
        <Route exact path="/settings/team/join" component={SettingsTeamJoinPage}/>
      </ApplicationLayout>
    </Router>
  </ConnectedRouter>
)

export default router
