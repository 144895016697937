import React, { useEffect, useRef, useState } from "react"


export default function withBridge(Wrapped) {
  return (props) => {
    const recipeDomRef = useRef()
    const [state, setState] = useState(new Date().getTime())
    useEffect(() => {
      const ref = recipeDomRef.current
      ref.addEventListener("refresh-recipe", (e) => {
        setState(new Date().getTime())
      })
      return () => {
        ref.removeEventListener("refresh-recipe", null)
      }
    }, [recipeDomRef])
    return (<div className="single-recipe" ref={recipeDomRef}>
      <Wrapped {...props} reupdater={state}/>
    </div>)

  }
}
//
// export default function withBridge(Wrapped) {
//   return class SnowflakeBridge extends Component {
//     constructor(props) {
//       super(props)
//
//     }
//
//     render() {
//       return (
//         <div className="single-recipe" ref={this.recipeDomRef}>
//           <Wrapped {...this.props}/>
//         </div>
//       )
//     }
//
//     componentDidMount() {
//       this.recipeDomRef.current.addEventListener("refresh-recipe", () => {
//         this.forceUpdate()
//       })
//     }
//
//   }
// }