import React, {Component} from 'react';
import PropTypes from 'prop-types';
import "./panel.scss";
import {setFieldState} from "../px-status/actions/fieldState";
import {connect} from 'react-redux'


class Panel extends Component {

    isControlled = () => !!this.props.statusKey

    getStatus = () => {
        const status = this.props.statuses.find(o => o.id === this.props.statusKey)
        return !(status ? status.value : '')
    }

    setStatus = (value) => this.props.dispatch(setFieldState(this.props.statusKey, value))

    toggleVisible = () => {

        if (!this.isControlled()) {
            console.log('not controlled')
            return this.setState({visible: !this.state.visible})
        }
        this.setStatus(this.getStatus() ? 'hidden' : '')
    }

    constructor() {
        super()
        this.state = {
            visible: true
        }
    }

    render() {


        const {title, style, bodyStyle, headerStyle, className, children, onClick, headerActions} = this.props;
        const visible = this.isControlled() ? this.getStatus() : this.state.visible
        const componentClassName = 'panel panel-default m-b-lg ' + (className || '')
        return (<section className={componentClassName} onClick={onClick} style={style}>
                {title ? <header style={headerStyle} className="panel-heading text-uc">
                    <div onClick={this.toggleVisible}>
                        {title}
                    </div>

                    {headerActions ? (<div className="panel-actions">
                        {headerActions}
                    </div>) : null}

                </header> : null}
                {
                    visible ? (
                        <section className="panel-body" style={bodyStyle || {}}>
                            {children}
                        </section>
                    ) : null
                }

            </section>
        )
    }
}

Panel.propTypes = {
    headerActions: PropTypes.array,
    statusKey: PropTypes.string,
    style: PropTypes.object,
    bodyStyle: PropTypes.object,
    headerStyle: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
};
Panel.defaultProps = {
    statusKey: '',
    style: {},
    bodyStyle: {},
    headerStyle: {},
    className: '',
    title: ''
};

export default connect((store) => ({
    statuses: store.fieldState.collection
}))(Panel);
