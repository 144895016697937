import React from 'react'
//import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import AbstractAuthComponent from './components/AbstractAuthComponent'
import connector from './userAuthConnector'
import {Lock} from '@material-ui/icons'
import {DialogConnect} from "./LdapComponent";
import Panel from "../../layout/panel/Panel";


const iconStyle = {
    display: 'block',
    margin: 'auto',
    height: '40px',
    width: '40px'
}


export class GrantComponent extends AbstractAuthComponent {

    constructor() {
        super()
        this.state = {
            authVisible: false
        }
    }

    toggleAuth = (status) => this.setState({authVisible: typeof status === 'boolean' ? status : !this.state.authVisible})

    authorized = (granted) => granted ? this.props.children : this.unAuthorized()

    unAuthorized = () => (<div>
        <Panel>
            <div className="text-center" style={{padding: 30}}>
                <div style={{lineHeight: '80px'}}>
                    <Lock style={iconStyle} viewBox='0 0 24 24'/>
                </div>
                <h3>Not Authorized</h3>
                <p className="text-muted">you are successfuly logged but you are not allowed to access this resource</p>
            </div>
        </Panel>
    </div>)

    offlineContent = () => (<div style={this.props.style}>
        <DialogConnect open={this.state.authVisible} close={() => this.toggleAuth(false)}/>
        <Panel style={{margin: 40}}>
            <div onClick={() => this.toggleAuth(true)} className="text-center" style={{padding: 30}}>
                <div style={{lineHeight: '80px'}}>
                    <Lock style={iconStyle} viewBox='0 0 24 24'/>
                </div>
                <h3>please login</h3>
                <p className="text-muted">Please provide first your credentials</p>
            </div>
        </Panel>
    </div>)

    render() {
        return this.isLogged() ? this.authorized(this.isLogged()) : this.offlineContent()
    }
}


GrantComponent.propTypes = {

}
GrantComponent.defaultProps = {

}


export class AuthorizedComponent extends AbstractAuthComponent {

    authorized = () => this.props.children

    unAuthorized = () => null

    render() {
        return this.isLogged() && this.isGranted() ? this.authorized() : this.unAuthorized()
    }
}

AuthorizedComponent.propTypes = {

}
AuthorizedComponent.defaultProps = {

}


export const Authorized = connect(connector)(AuthorizedComponent)



export default connect(connector)(GrantComponent)

